import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import { apiClient } from "../../../config/api-client";
import { Cart, CartLine } from "../../../models";

type DeleteCartLineDTO = {
    cartKey: Cart["panier_cle"];
    lineId: CartLine["id"];
};

export async function deleteCartLine(request: DeleteCartLineDTO): Promise<AxiosInstance> {
    const { cartKey, lineId } = request;
    return apiClient.delete(`/panier/${cartKey}/lignePanier/${lineId}`);
}

export function useDeleteCartLine() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteCartLine,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cart"] }),
    });

    return mutation;
}
