import {
    Package,
    PackageSearch,
    useGetPackagePrices,
    useSearchContext,
} from "@resamare/widgets-shared";
import { PackageCard } from "./PackageCard/PackageCard";

type PackageItemProps = {
    onClick: (packageItem: Package) => void;
    packageItem: Package;
};

export function PackageItem({ onClick, packageItem }: PackageItemProps) {
    const { search } = useSearchContext<PackageSearch>() as { search: PackageSearch };

    const pricesQuery = useGetPackagePrices({
        packageId: packageItem.id,
        participants: search?.participants,
    });

    return (
        <PackageCard
            onClick={onClick}
            packageItem={packageItem}
            priceFrom={pricesQuery.data?.prix_apartirde}
        />
    );
}
