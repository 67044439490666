export function getInputNames(form: HTMLFormElement | null) {
    const inputNames: string[] = [];
    form?.querySelectorAll<HTMLInputElement>('input').forEach((input) =>
        inputNames.push(input.name)
    );
    return inputNames;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objectToFormData(obj: any, form = new FormData(), namespace = '') {
    if (obj == null || typeof obj !== 'object') {
        return form;
    }

    Object.keys(obj).forEach((property) => {
        const formKey = namespace ? `${namespace}[${property}]` : property;
        const value = obj[property];

        if (value instanceof Date) {
            form.append(formKey, value.toISOString());
        } else if (typeof value === 'object' && !(value instanceof File)) {
            objectToFormData(value, form, formKey);
        } else {
            form.append(formKey, value);
        }
    });

    return form;
}

export const createAndSubmitForm = (form: string) => {
    const element = document.createElement('div');
    element.innerHTML = form;
    const formElement = element.querySelector<HTMLFormElement>('form')!;
    formElement.style.display = 'none';
    document.body.appendChild(formElement);
    formElement.submit();
};
