import { Card, Divider, Stack, Typography } from "@resamare/ui";
import { Liaison, NotAvailableMessage } from "@resamare/widgets-shared";
import { DateFormat, dt } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { LiaisonCardFooter } from "./LiaisonCardFooter";

import styles from "./LiaisonCard.module.scss";

type LiaisonCardProps = {
    onClick: () => void;
    liaison: Liaison;
};

export function LiaisonCard({ liaison, onClick }: LiaisonCardProps) {
    const { t } = useTranslation();

    const departures = liaison.lieux_depart.map((place) => place.label);
    const destinations = liaison.lieux_destination.map((place) => place.label);

    const departureDate = dt.fromISO(dt.removeIsoOffset(liaison.date_depart));
    const arrivalDate = dt.fromISO(dt.removeIsoOffset(liaison.date_fin));

    const duration = dt.formatDuration(dt.getDiff(departureDate, arrivalDate));

    return (
        <Card className={styles["liaison-card"]} variant="outlined">
            <Stack className={styles["liaison-card__details"]} gap={2}>
                <div>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack>
                            <Typography variant="title-xs">
                                {dt.formatLocaleTime(departureDate)}
                            </Typography>
                        </Stack>
                        <Stack className={styles["liaison-card__duration"]}>
                            <Typography variant="text-sm">{duration}</Typography>
                        </Stack>
                        <Stack>
                            <Typography align="right" variant="title-xs">
                                {dt.formatLocaleTime(arrivalDate)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography align="right" variant="text-sm">
                            {dt.formatLocale(departureDate, DateFormat.DATE_MED)}
                        </Typography>
                        <Typography align="right" variant="text-sm">
                            {dt.formatLocale(arrivalDate, DateFormat.DATE_MED)}
                        </Typography>
                    </Stack>
                </div>
                <div className={styles["liaison-card__places"]}>
                    <Typography fontWeight={600}>
                        {t("Départ", { count: departures.length })}
                    </Typography>
                    <Typography>{departures.join(", ")}</Typography>
                    <Typography fontWeight={600}>
                        {t("Arrivée", { count: destinations.length })}
                    </Typography>
                    <Typography>{destinations.join(", ")}</Typography>
                </div>
                {liaison.dispo_vente ? null : <NotAvailableMessage />}
            </Stack>
            <div className={styles["liaison-card__divider"]}>
                <Divider />
            </div>
            <LiaisonCardFooter liaison={liaison} onClick={onClick} />
        </Card>
    );
}
