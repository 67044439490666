import { Navigate, useNavigate } from "react-router-dom";
import { Link, Spinner, Stack } from "@resamare/ui";
import { isEmpty } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import {
    EmptyCartMessage,
    OrderSummary,
    Section,
    useClientConfig,
    useGetCart,
} from "@resamare/widgets-shared";

export function OrderSummaryPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { config } = useClientConfig();

    const cartQuery = useGetCart();

    if (config.activer_panier === false) {
        return <Navigate to="/checkout/checkout-form" />;
    }

    if (cartQuery.isLoading) {
        return (
            <Section>
                <Spinner />
            </Section>
        );
    }

    if (
        !cartQuery.isLoading &&
        (!cartQuery.data ||
            (isEmpty(cartQuery.data.ligne_package) && isEmpty(cartQuery.data.ligne_panier)))
    ) {
        return (
            <Section>
                <EmptyCartMessage onClick={() => navigate("/")} />
            </Section>
        );
    }

    return (
        <Section>
            <Stack gap={3}>
                <Link to="/">{t("Retour à la recherche")}</Link>
                <OrderSummary
                    cart={cartQuery.data}
                    onContinueShopping={() => navigate("/")}
                    onGoNextStep={() => navigate("/checkout/checkout-form")}
                />
            </Stack>
        </Section>
    );
}
