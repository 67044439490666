import { Button, Divider, Drawer, FormBuilder, Spinner, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { SubmitHandler } from "react-hook-form";
import { formatCurrency, isEmpty } from "@resamare/functions";
import { useFormSubmitter } from "@resamare/react-hooks";
import { OverlayFooter, OverlayHeader } from "../../../components";
import { ModalLayout } from "../../../layouts";

import { Liaison, Session } from "../../../models";
import { ExcursionSearch, useSearchContext } from "../../search";
import { useGetSessionOptionsForm } from "../../sessions";
import { ExcursionOptionsValues } from "../../activity-types/excursion";
import { OptionDetails } from "../types";
import { useOptionsTotal } from "../hooks/useOptionsTotal";

type OptionsModalProps = {
    isLoading?: boolean;
    isOpen: boolean;
    onClose: () => void;
    onMount?: React.ComponentProps<typeof FormBuilder>["onMount"];
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    options?: OptionDetails[];
    renderBefore: () => React.ReactNode;
    session: Session | Liaison;
};

export function OptionsFormModal({
    isOpen,
    isLoading,
    onClose,
    onMount,
    onSubmit,
    options,
    renderBefore = () => null,
    session,
}: OptionsModalProps) {
    const { t } = useTranslation();

    const { formRef, submitForm } = useFormSubmitter();

    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };
    const optionsQuery = useGetSessionOptionsForm(session.id, {
        participants: search.participants,
    });

    const totalOptions = useOptionsTotal(options);

    if (optionsQuery.isLoading) {
        return null;
    }

    const handleOnSubmit = (data: ExcursionOptionsValues) => {
        onSubmit(data);
    };

    return (
        <Drawer isOpen={isOpen} maxWidth={560} onClose={onClose}>
            <OverlayHeader onClose={onClose}>{t("Options")}</OverlayHeader>
            <ModalLayout>
                {optionsQuery.isLoading ? <Spinner /> : null}
                {optionsQuery.data ? (
                    <FormBuilder
                        data={optionsQuery.data}
                        formRef={formRef}
                        onMount={(schema) => onMount && onMount(schema)}
                        onSubmit={handleOnSubmit}
                    >
                        {renderBefore()}
                        {!isEmpty(optionsQuery.data.sections) ? (
                            <Divider>{t("Personnalisez votre réservation")}</Divider>
                        ) : null}
                    </FormBuilder>
                ) : null}
            </ModalLayout>
            <OverlayFooter>
                <Stack direction="row" justifyContent="space-between">
                    <Stack alignItems="flex-start">
                        {session.prix_apartirde ? (
                            <>
                                <Typography variant="text-sm">Total</Typography>
                                <Stack alignItems="baseline" direction="row" gap={1}>
                                    <Typography variant="title-sm">
                                        {formatCurrency(session.prix_total + totalOptions)}
                                    </Typography>
                                </Stack>
                            </>
                        ) : null}
                    </Stack>
                    <Button isLoading={isLoading} onClick={submitForm}>
                        {t("Suivant")}
                    </Button>
                </Stack>
            </OverlayFooter>
        </Drawer>
    );
}
