const prefix = 'resamare_';
const ONE_DAY_IN_MS = 86400000;

interface StoredItem {
    expiry: number;
    data: any;
}

export const storage = {
    get: <T extends object | string>(key: string): T | null => {
        const item = window.localStorage.getItem(`${prefix}${key}`);
        if (!item) return null;

        return JSON.parse(item);
    },

    set: (key: string, data: any) => {
        window.localStorage.setItem(`${prefix}${key}`, JSON.stringify(data));
    },

    remove: (key: string) => {
        window.localStorage.removeItem(`${prefix}${key}`);
    },

    setWithExpiry: (key: string, data: any, ttl = ONE_DAY_IN_MS) => {
        const now = new Date();
        const item = { data, expiry: now.getTime() + ttl };
        storage.set(key, JSON.stringify(item));
    },

    getWithExpiry: <T extends object | string>(key: string): T | null => {
        const savedItem = storage.get<string>(key);
        if (!savedItem) return null;

        const item: StoredItem = JSON.parse(savedItem);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            storage.remove(key);
            return null;
        }

        return item.data;
    },
};
