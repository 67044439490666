import { Link, Spinner, Stack } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { Checkout, Section, useClientConfig, useGetCart } from "@resamare/widgets-shared";

export function CheckoutFormPage() {
    const { t } = useTranslation();
    const { config } = useClientConfig();

    const cartQuery = useGetCart();

    return (
        <Section>
            <Stack gap={3}>
                {config.activer_panier ? (
                    <Link to="/checkout/order-summary">{t("Retour au panier")}</Link>
                ) : (
                    <Link to="/">{t("Retour à la recherche")}</Link>
                )}

                {cartQuery.isLoading ? <Spinner /> : null}

                {cartQuery.data ? <Checkout cart={cartQuery.data} /> : null}
            </Stack>
        </Section>
    );
}
