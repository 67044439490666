/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { FormType } from "@resamare/widgets-shared";

import { DefaultRoute } from "./DefaultRoute";
import { ExcursionsRoutes } from "@/features/excursions";
import { LiaisonsRoutes } from "@/features/liaisons";
import { CheckoutRoutes } from "@/features/checkout";
import { PackagesRoutes } from "@/features/packages";
import { NavibusRoutes } from "@/features/navibus";

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<DefaultRoute />} />
            <Route path={`/${FormType.EXCURSION}/*`} element={<ExcursionsRoutes />} />
            <Route path={`/${FormType.LIAISON}/*`} element={<LiaisonsRoutes />} />
            <Route path={`/${FormType.PACKAGE}/*`} element={<PackagesRoutes />} />
            <Route path="/NAVIBUS/*" element={<NavibusRoutes />} />
            <Route path="/checkout/*" element={<CheckoutRoutes />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
