/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { SearchLayout } from "@/layouts/SearchLayout/SearchLayout";
import { LiaisonsPage } from "../pages";

export function LiaisonsRoutes() {
    return (
        <Routes>
            <Route element={<SearchLayout />}>
                <Route path="/:productTypeId" element={<LiaisonsPage />} />
                <Route path="*" element={<Navigate to="." />} />
            </Route>
        </Routes>
    );
}
