import { yupResolver } from "@hookform/resolvers/yup";
import Yup, { ObjectSchema, ValidateOptions } from "yup";
import {
    FieldValues as FieldValuesRHF,
    UseFormProps as UseFormPropsRHF,
    useForm as useFormRHF,
    UseFormReturn as UseFormReturnRHF,
    Resolver,
} from "react-hook-form";

export interface UseFormProps<FormValues extends FieldValuesRHF> {
    defaultValues?: UseFormPropsRHF<FormValues>["defaultValues"];
    options?: Omit<UseFormPropsRHF<FormValues>, "defaultValues" | "resolver">;
    schema?: ObjectSchema<FormValues, Yup.AnyObject>;
    schemaOptions?: ValidateOptions;
}

export function useForm<FormValues extends FieldValuesRHF>({
    defaultValues,
    options,
    schema,
    schemaOptions,
}: UseFormProps<FormValues>): UseFormReturnRHF<FormValues> {
    const resolver = schema
        ? (yupResolver(schema, schemaOptions) as unknown as Resolver<FormValues>)
        : undefined;

    return useFormRHF<FormValues>({
        defaultValues,
        resolver,
        ...options,
    });
}
