import { useEffect, useRef, useState } from "react";
import { useElementSize } from "@resamare/react-hooks";

import { ButtonLink } from "../ButtonLink/ButtonLink";

import styles from "./ReadMore.module.scss";

type ReadMoreProps = {
    buttonTexts?: {
        readMore?: string;
        readLess?: string;
    };
    children: React.ReactNode;
    nbOfLines?: number;
    onClick?: () => void;
};

const defaultButtonTexts = {
    readMore: "... Lire la suite",
    readLess: "Réduire",
};

export function ReadMore({ buttonTexts, children, nbOfLines = 3, onClick }: ReadMoreProps) {
    const readMoreRef = useRef<HTMLDivElement>(null);
    const readMoreInitialHeight = useRef<number>(0);
    const [isOpen, setIsOpen] = useState(false);

    const texts = { ...defaultButtonTexts, ...buttonTexts };

    const [containerRef] = useElementSize<HTMLDivElement>();

    const scrollHeight = readMoreRef.current?.scrollHeight || 0;
    const displayButton = scrollHeight > readMoreInitialHeight.current;

    const showText = () => {
        if (onClick) {
            onClick();
            return;
        }
        if (readMoreRef.current) {
            readMoreRef.current.style.display = "block";
        }
        setIsOpen(true);
    };

    const hideText = () => {
        if (readMoreRef.current) {
            readMoreRef.current.style.display = "-webkit-box";
        }
        readMoreInitialHeight.current = readMoreRef.current?.offsetHeight || 0;
        setIsOpen(false);
    };

    useEffect(() => {
        readMoreRef.current?.style.setProperty("--lines", nbOfLines.toString());
        hideText();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nbOfLines]);

    return (
        <div ref={containerRef}>
            <div ref={readMoreRef} className={styles["read-more"]}>
                {children}
                {displayButton && !isOpen ? (
                    <div className={styles["read-more__show-button"]}>
                        <ButtonLink onClick={showText}>{texts.readMore}</ButtonLink>
                    </div>
                ) : null}
            </div>
            {displayButton && isOpen ? (
                <ButtonLink onClick={hideText}>{texts.readLess}</ButtonLink>
            ) : null}
        </div>
    );
}
