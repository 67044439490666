import { RefObject, useCallback, useRef } from 'react';

interface UseScrollToRefParams<T extends HTMLElement> {
    ref?: RefObject<T>;
    offsetTop?: number;
    behavior?: ScrollBehavior;
}

export const useScrollToRef = <T extends HTMLElement = HTMLDivElement>({
    ref,
    offsetTop = 0,
    behavior = 'smooth',
}: UseScrollToRefParams<T> = {}): {
    ref: RefObject<T>;
    scrollToRef: () => void;
} => {
    const internalRef = useRef<T>(null);
    const elementRef = ref || internalRef;

    const scrollToRef = useCallback(() => {
        if (elementRef.current) {
            const y = elementRef.current.getBoundingClientRect().top + window.scrollY + offsetTop;
            window.scrollTo({ top: y, behavior });
        }
    }, [elementRef, offsetTop]);

    return { ref: elementRef, scrollToRef };
};
