import { formatCurrency } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Button, Stack, Strikethrough, Typography } from "@resamare/ui";
import { Liaison } from "@resamare/widgets-shared";

import styles from "./LiaisonCard.module.scss";

type LiaisonCardFooterProps = {
    onClick: () => void;
    liaison: Liaison;
};

export function LiaisonCardFooter({ liaison, onClick }: LiaisonCardFooterProps) {
    const { t } = useTranslation();

    return (
        <Stack className={styles["liaison-card__footer"]} gap={1}>
            <Stack>
                <Typography variant="text-sm">{t("À partir de")}</Typography>
                <Stack alignItems="baseline" direction="row" gap={1}>
                    <Typography variant="title-sm">
                        {formatCurrency(liaison.prix_apartirde || liaison.prix_total)}
                    </Typography>

                    {liaison.prix_base > liaison.prix_total ? (
                        <Strikethrough>
                            <Typography fontWeight={600} variant="text-sm">
                                {formatCurrency(liaison.prix_base)}
                            </Typography>
                        </Strikethrough>
                    ) : null}
                </Stack>
            </Stack>
            {liaison.dispo_vente ? <Button onClick={onClick}>{t("Sélectionner")}</Button> : null}
        </Stack>
    );
}
