import { Spinner, useErrorBoundary } from "@resamare/ui";
import { Navigate } from "react-router-dom";
import { Section, useDefaultConfigSearch, useGetProductTypes } from "@resamare/widgets-shared";

export function DefaultRoute() {
    const productTypesQuery = useGetProductTypes();
    const defaultSearch = useDefaultConfigSearch();
    const { showBoundary } = useErrorBoundary();

    if (productTypesQuery.isLoading) {
        return (
            <Section>
                <Spinner />
            </Section>
        );
    }

    if (productTypesQuery.data) {
        const searchProductType = productTypesQuery.data.find(
            (type) => type.value === defaultSearch?.type_produit,
        );
        const defaultProductType = searchProductType || productTypesQuery.data[0];
        const activityType = defaultProductType.type_form;
        return <Navigate to={`/${activityType}/${defaultProductType.value}`} />;
    }

    showBoundary(new Error("Aucun type de produit n'a été récupéré"));
    return null;
}
