import { dt, flat } from "@resamare/functions";
import { apiClient, Liaison, NavibusSearch, Product } from "@resamare/widgets-shared";
import { useQueries } from "@tanstack/react-query";

type GetNavibusSessionsDTO = {
    date_deb: Date;
    lieu_depart?: string[];
    lieu_destination?: string[];
    participants: Record<string, number>;
    produit_id?: number;
};

async function getNavibusSessions(params: GetNavibusSessionsDTO): Promise<Liaison[]> {
    const { data } = await apiClient.get<{ liste_produit: Product[] }>("/recherche", {
        params: {
            ...params,
            date_deb: dt.toIsoDate(params.date_deb),
            participants: JSON.stringify(params.participants),
        },
    });

    const liaisons = flat(data.liste_produit.map((product) => product.sessions));

    return liaisons as unknown as Liaison[];
}

export function useGetNavibusSessions({ params }: { params: NavibusSearch | null }) {
    const steps = params?.itinerary
        .map((step, index) => [step, params.itinerary[index + 1]])
        .slice(0, -1);

    return useQueries({
        queries:
            steps && params
                ? steps.map((step) => {
                      const stepParams = {
                          date_deb: params.date_deb,
                          participants: params.participants,
                          lieu_depart: [step[0]],
                          lieu_destination: [step[1]],
                          produit_id: params.produit_id,
                      };

                      return {
                          queryKey: ["navibus-sessions", stepParams],
                          queryFn: () => getNavibusSessions(stepParams),
                          cacheTime: 0,
                      };
                  })
                : [],
    });
}
