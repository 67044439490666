import { useRef } from "react";

export const useFormSubmitter = () => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const submitForm = () => {
    formRef.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  return { formRef, submitForm };
};

export const useMultipleFormSubmitter = () => {
  const formRefs = useRef<(HTMLFormElement | null)[]>([]);

  const submitForms = () => {
    formRefs.current.forEach((formRef) => {
      if (formRef) {
        formRef.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }
    });
  };

  return { formRefs, submitForms };
};
