import { dt } from "@resamare/functions";
import { Liaison } from "@resamare/widgets-shared";
import { UseQueryResult } from "@tanstack/react-query";

export const useNavibusLogic = (queries: UseQueryResult<Liaison[], unknown>[]) => {
    if (queries.length === 0) {
        return null;
    }

    const isLoading = queries.find((query) => query.isLoading);

    if (queries.length === 1) {
        return {
            isLoading,
            isMultipleSections: false,
            liaisons: queries[0].data,
        };
    }

    const firstLiaison = queries[0].data?.[0];
    const secondLiaison = firstLiaison
        ? queries[1].data?.find((liaison) =>
              dt.isAfter(dt.fromISO(liaison.date_depart), dt.fromISO(firstLiaison.date_fin)),
          )
        : undefined;

    const isStepoverPossible = firstLiaison !== undefined && secondLiaison !== undefined;

    const liaisons = isStepoverPossible ? [firstLiaison, secondLiaison] : null;

    return {
        isLoading,
        isMultipleSections: true,
        liaisons,
    };
};
