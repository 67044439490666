import { useQuery, useQueryClient } from "@tanstack/react-query";

import { apiClient } from "../../../config";
import { Liaison } from "../../../models";

export async function getLiaison(id: string): Promise<Liaison> {
    const response = await apiClient.get(`/liaison/${id}`);

    return response.data;
}

export function useGetLiaison(id: string) {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: ["liaisons", id],
        queryFn: () => getLiaison(id),
        enabled: !!id,
        staleTime: Infinity,
        cacheTime: 0,
        initialData: () => queryClient.getQueryData(["liaisons", id]),
    });

    return query;
}
