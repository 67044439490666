/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import { Typography, TypographyProps } from "../Typography/Typography";

import styles from "./Link.module.scss";

declare module "@mui/material/Link" {
    interface LinkPropsColorOverrides {
        primaryLink?: true;
    }
}

interface LinkProps extends Pick<MuiLinkProps, "target"> {
    color?: "primary" | "error" | "inherit";
    children?: string;
    href?: string;
    to?: string;
    variant?: TypographyProps["variant"];
}

export function Link({
    children,
    color = "primary",
    href,
    to,
    target,
    variant = "text",
}: LinkProps) {
    return (
        <MuiLink
            className={styles.link}
            color={color === "primary" ? "primaryLink.main" : color}
            component={to ? RouterLink : "a"}
            href={href}
            target={target}
            to={to}
            underline={isDesktop ? "hover" : "always"}
        >
            <Typography component="span" fontWeight={600} variant={variant}>
                {children}
            </Typography>
        </MuiLink>
    );
}
