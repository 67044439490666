import { ErrorIcon } from "../../../General";
import { Stack } from "../../../Layout";
import { FormHelperText } from "../FormHelperText/FormHelperText";

import Styles from "./FormErrorMessage.module.scss";

export interface FormErrorMessageProps {
    children: string;
}

export function FormErrorMessage({ children }: FormErrorMessageProps) {
    return (
        <Stack className={Styles.ErrorContainer} direction="row" gap={0.5}>
            <ErrorIcon color="error" size="small" />
            <FormHelperText>{children}</FormHelperText>
        </Stack>
    );
}
