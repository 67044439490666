import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FormData } from "@resamare/ui";
import { useState } from "react";
import { ExcursionSearch } from "../../search";
import { apiClient } from "../../../config";

type GetOptionsFormDTO = {
    participants: ExcursionSearch["participants"];
    lineId?: number;
};

async function getOptionsForm(sessionId: string, params: GetOptionsFormDTO): Promise<FormData> {
    const { data } = (await apiClient.get(`sessions/${sessionId}/options-form`, {
        params: {
            participants: JSON.stringify(params.participants),
            ligne_panier_id: params.lineId,
        },
    })) as { data: FormData };

    if (data.sections === null) {
        return { sections: [] };
    }

    return data;
}

export function useGetSessionOptionsForm(sessionId: string, params: GetOptionsFormDTO) {
    const queryKey = ["options-form", { sessionId, ...params }];

    const query = useQuery({
        queryKey,
        queryFn: () => getOptionsForm(sessionId, params),
        enabled: !!sessionId,
    });

    return query;
}

export function usePrefetchSessionOptionsForm(sessionIds: string[], params: GetOptionsFormDTO) {
    const queryClient = useQueryClient();

    const prefetchOptions = () => {
        sessionIds.forEach((sessionId) => {
            const queryKey = ["options-form", { sessionId, ...params }];
            queryClient.prefetchQuery({
                queryKey,
                queryFn: () => getOptionsForm(sessionId, params),
            });
        });
    };

    return prefetchOptions;
}

export function useGetAdditionnalOptionsForm() {
    const queryClient = useQueryClient();

    const [isLoading, setIsLoading] = useState(false);

    const getAdditionnalOptions = async (sessionId: string, params: GetOptionsFormDTO) => {
        setIsLoading(true);

        if (!sessionId || !params) return null;

        try {
            const queryKey = ["options-form", { sessionId, ...params }];
            const data = await queryClient.fetchQuery({
                queryKey,
                queryFn: () => getOptionsForm(sessionId, params),
                meta: { skipLogHttpErrors: [404] },
            });
            return data;
        } finally {
            setIsLoading(false);
        }
    };

    return { getAdditionnalOptions, isLoading };
}
