import { IconButton, ReadMore, ShoppingBasketIcon, Stack, Typography } from "@resamare/ui";
import { removeHtml } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { useClientConfig } from "@resamare/widgets-shared";
import { useNavigate } from "react-router-dom";

export function Intro() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { config } = useClientConfig();

    return (
        <>
            <Stack alignItems="flex-start" direction="row" gap={1} justifyContent="space-between">
                {config.titre ? (
                    <Typography variant="title-xs">{config.titre}</Typography>
                ) : (
                    <Typography variant="title-xs">
                        {t("Trouvez et réservez votre balade avec")} {config.nom}
                    </Typography>
                )}
                {config.activer_panier ? (
                    <IconButton onClick={() => navigate("/checkout")}>
                        <ShoppingBasketIcon />
                    </IconButton>
                ) : null}
            </Stack>
            <ReadMore
                buttonTexts={{ readMore: `... ${t("Lire la suite")}`, readLess: t("Réduire") }}
                nbOfLines={4}
            >
                <Typography>{removeHtml(config.texte_principale)}</Typography>
            </ReadMore>
        </>
    );
}
