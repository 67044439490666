/* eslint-disable @typescript-eslint/no-use-before-define */
import { Spinner, Stack, Typography } from "@resamare/ui";
import {
    FormType,
    Section,
    useClientConfig,
    useGetProductTypes,
    useSearchContext,
} from "@resamare/widgets-shared";
import { useTranslation } from "@resamare/translation";
import { Intro } from "./components/Intro";
import { Navigation } from "./components/Navigation";
import { ExcursionForm } from "@/features/excursions";
import { useUrlActivityType } from "./hooks/useUrlActivityType";
import { LiaisonForm } from "@/features/liaisons";
import { PackageForm } from "@/features/packages";
import { NavibusFormLogic } from "@/features/navibus";

import styles from "./SearchModule.module.scss";

const FORMS = {
    [FormType.EXCURSION]: <ExcursionForm />,
    [FormType.LIAISON]: <LiaisonForm />,
    [FormType.NAVIBUS]: <NavibusFormLogic />,
    [FormType.PACKAGE]: <PackageForm />,
};

export function SearchModule() {
    const { setSearch } = useSearchContext();
    const { config } = useClientConfig();
    const productTypesQuery = useGetProductTypes();

    const activityType = useUrlActivityType() as FormType;
    if (!Object.keys(FORMS).includes(activityType)) {
        throw new Error("Activity type not found");
    }

    const handleOnChange = () => {
        setSearch(null);
    };

    return (
        <div
            className={styles["search-background"]}
            style={{ backgroundImage: `url(${config.image})` }}
        >
            <Section>
                {config.vente_en_ligne ? (
                    <Stack className={styles["search-content"]}>
                        <Stack className={styles["search-container"]} gap={1}>
                            <Intro />
                        </Stack>
                        {productTypesQuery.isLoading ? <Spinner /> : null}
                        {productTypesQuery.data ? (
                            <>
                                <Navigation
                                    onChange={handleOnChange}
                                    productTypes={productTypesQuery.data}
                                />
                                <div className={styles["search-container"]}>
                                    {FORMS[activityType]}
                                </div>
                            </>
                        ) : null}
                    </Stack>
                ) : (
                    <SellingDisabledMessage />
                )}
            </Section>
        </div>
    );
}

function SellingDisabledMessage() {
    const { t } = useTranslation();

    const { config } = useClientConfig();

    return (
        <Stack className={styles["search-content"]}>
            <Stack className={styles["search-container"]} gap={1}>
                <Typography variant="title-xs">
                    {t("La réservation en ligne n'est pas disponible pour le moment.")}{" "}
                </Typography>
                {config.texte_vente_desactivee ? (
                    <Typography component="div">
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: config.texte_vente_desactivee,
                            }}
                        />
                    </Typography>
                ) : null}
            </Stack>
        </Stack>
    );
}
