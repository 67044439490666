/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { ScrollToTop } from "@resamare/ui";
import { useClientConfig } from "@resamare/widgets-shared";
import { useRef } from "react";
import { OrderConfirmationPage } from "../pages/OrderConfirmationPage";
import { CheckoutFormPage, CheckoutIndexPage, OrderSummaryPage } from "../pages";

export function CheckoutRoutes() {
    const { config } = useClientConfig();

    const rootElement = useRef<HTMLDivElement | null>(null);

    return (
        <div ref={rootElement}>
            <ScrollToTop
                element={rootElement}
                offsetTop={config.offsetTop ? -parseInt(config.offsetTop, 10) : undefined}
            />
            <Routes>
                <Route path="/" element={<CheckoutIndexPage />} />
                <Route path="/order-summary" element={<OrderSummaryPage />} />
                <Route path="/checkout-form" element={<CheckoutFormPage />} />
                <Route path="/order-confirmation" element={<OrderConfirmationPage />} />
                <Route path="*" element={<Navigate to="./order-summary" />} />
            </Routes>
        </div>
    );
}
