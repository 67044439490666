import { useQuery } from "@tanstack/react-query";

import { dt, unique } from "@resamare/functions";
import { apiClient } from "../../../config";

export type GetNextDatesAvailableDTO = {
    date_deb: Date;
    participants: Record<string, number>;
    type_produit?: number;
    produit?: number;

    lieu_depart?: string[];
    lieu_destination?: string[];
    ville_depart?: string[];
    ville_destination?: string[];

    date_min?: Date;
};

export async function getNextDatesAvailable(params: GetNextDatesAvailableDTO): Promise<string[]> {
    if (!params) {
        throw new Error("Vous devez envoyer des paramètres pour récupérer les prochaines dates");
    }

    const { data } = await apiClient.get("/prochaines-dates-disponibles", {
        params: {
            ...params,
            date_deb: dt.toIsoDate(params.date_deb),
            participants: JSON.stringify(params.participants),
        },
    });
    return unique(data);
}

type UseGetNextDatesAvailableOptions = {
    params: GetNextDatesAvailableDTO | null;
};

export function useGetNextDatesAvailable({ params }: UseGetNextDatesAvailableOptions) {
    const query = useQuery({
        queryKey: ["next-dates-availables", params],
        queryFn: () => getNextDatesAvailable(params as GetNextDatesAvailableDTO),
        enabled: !!params,
        keepPreviousData: false,
        cacheTime: 0,
    });

    return query;
}
