import { useScrollToRef } from "@resamare/react-hooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

type ScrollToTopProps = {
    element?: React.MutableRefObject<HTMLElement | null>;
    offsetTop?: number;
};

export function ScrollToTop({ element, offsetTop = 0 }: ScrollToTopProps) {
    const { pathname } = useLocation();
    const { scrollToRef } = useScrollToRef({ ref: element, offsetTop, behavior: "auto" });

    useEffect(() => {
        if (element?.current) {
            scrollToRef();
            return;
        }
        window.scrollTo(0, -110);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return null;
}
