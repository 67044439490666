import { useEffect } from "react";
import { FormHidden, Stack, useForm } from "@resamare/ui";
import {
    DepartureLocationOption,
    DepartureTimeOption,
    ExcursionOptionsValues,
    Liaison,
    LiaisonSearch,
    OptionsFormModal,
    SelectedLiaison,
    getExcursionOptionsSchema,
    useGetOptions,
    useSearchContext,
} from "@resamare/widgets-shared";
import { FormProvider } from "react-hook-form";
import { dt } from "@resamare/functions";

type LiaisonOptionsProps = {
    isOpen: boolean;
    liaison: Liaison;
    onClose: () => void;
    onSelect: (data: SelectedLiaison) => void;
};
export function LiaisonOptions({ isOpen, liaison, onClose, onSelect }: LiaisonOptionsProps) {
    const { search } = useSearchContext<LiaisonSearch>() as { search: LiaisonSearch };

    const optionsQuery = useGetOptions({ date: dt.fromISO(dt.removeIsoOffset(liaison.date_fin)) });

    const form = useForm({
        defaultValues: {
            hour: liaison.heure_depart.value,
            lieu_id: liaison.lieux_depart[0].value,
            participants: search?.participants,
            session_id: parseInt(liaison.id, 10),
        },
        options: { shouldUnregister: true },
        schema: getExcursionOptionsSchema(),
    });

    useEffect(() => {
        form.setValue("participants", search?.participants);
    }, [form, search?.participants]);

    const handleOnSubmit = (data: Omit<ExcursionOptionsValues, "hour">) => {
        onSelect({
            date_fin: dt.fromISO(dt.removeIsoOffset(liaison.date_fin)),
            prix_apartirde: liaison.prix_apartirde || liaison.prix_total,
            ...data,
        });
        onClose();
    };

    return (
        <FormProvider {...form}>
            <OptionsFormModal
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={handleOnSubmit}
                options={optionsQuery.data}
                renderBefore={() => (
                    <Stack gap={3}>
                        <DepartureTimeOption sessions={[liaison]} />
                        <DepartureLocationOption session={liaison} />
                        <FormHidden name="session_id" />
                        <FormHidden name="participants" />
                    </Stack>
                )}
                session={liaison}
            />
        </FormProvider>
    );
}
