import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../../../config/api-client";

type RemoveCodeDTO = {
    cartKey: string;
    codeId: string;
};

export async function removeCode(request: RemoveCodeDTO): Promise<null> {
    const { cartKey, codeId } = request;

    return apiClient.delete(`/panier/${cartKey}/codePromo/${codeId}`);
}

export function useRemoveCode() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: removeCode,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["cart"] }),
    });

    return mutation;
}
