import { useQuery } from "@tanstack/react-query";
import { dt } from "@resamare/functions";
import { apiClient } from "../../../config";

type GetPackagePricesDTO = {
    date_deb?: Date;
    date_fin?: Date;
    lieu_depart?: string;
    lieu_destination?: string;
    packageId: number;
    participants: Record<string, number>;
};

export async function getPackagePrices(
    params: GetPackagePricesDTO,
): Promise<{ prix_apartirde: number }> {
    const { data } = await apiClient.get(`/packages/${params.packageId}/tarifs`, {
        params: {
            date_deb: params.date_deb ? dt.toIsoDate(params.date_deb) : null,
            date_fin: params.date_fin ? dt.toIsoDate(params.date_fin) : null,
            lieu_depart: params.lieu_depart || null,
            lieu_destination: params.lieu_destination || null,
            participants: JSON.stringify(params.participants),
        },
    });
    return data;
}

export function useGetPackagePrices(params: GetPackagePricesDTO) {
    const { packageId, ...rest } = params;

    const query = useQuery({
        queryKey: ["packages", packageId, { ...rest }],
        queryFn: () => getPackagePrices(params),
    });

    return query;
}
