import { dash } from 'radash';

export function formatCurrency(number: number) {
    return number.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' });
}

export function removeAccents(string: string) {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function removeHtml(string: string) {
    const div = document.createElement('div');
    div.innerHTML = string;
    return div.innerText;
}

export function slug(string: string) {
    return dash(removeAccents(string));
}

export { capitalize, uid } from 'radash';
