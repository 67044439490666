import { ReadMore, Stack, Typography } from "@resamare/ui";
import { removeHtml } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Package } from "@resamare/widgets-shared";

import styles from "./PackageCard.module.scss";

type PackageCardContentProps = {
    packageItem: Package;
    openDetails: () => void;
};

export function PackageCardContent({ packageItem, openDetails }: PackageCardContentProps) {
    const { t } = useTranslation();

    const isSingleProductPackage = packageItem.produits.length === 1;

    return (
        <Stack className={styles["package-card__content-inner"]} gap={2}>
            <Stack>
                <Typography variant="title-xs">{packageItem.nom}</Typography>
                {isSingleProductPackage
                    ? packageItem.produits.map((produit) => (
                          <Typography key={produit.id}>
                              {produit.quantite} x {produit.nom}
                          </Typography>
                      ))
                    : null}
            </Stack>

            {isSingleProductPackage ? (
                <div>
                    <Typography fontWeight={600}>{t("Programme")}</Typography>
                    <ReadMore nbOfLines={2} onClick={openDetails}>
                        <Typography>{removeHtml(packageItem.description)}</Typography>
                    </ReadMore>
                </div>
            ) : (
                <div>
                    <Typography fontWeight={600}>Ce package est composé de</Typography>
                    {packageItem.produits.map((produit) => (
                        <Typography key={produit.id}>
                            {produit.quantite} x {produit.nom}
                        </Typography>
                    ))}
                </div>
            )}
        </Stack>
    );
}
