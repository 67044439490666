import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config";

import { ParticipantType } from "../types";

export type ParticipantTypesDTO = {
    productId?: string;
    productTypeId?: string;
};

export async function getParticipantTypes(
    params?: ParticipantTypesDTO,
): Promise<ParticipantType[]> {
    const { productId, productTypeId } = params || {};

    const { data } = await apiClient.get("/infos", {
        params: { produit: productId, type_produit: productTypeId },
    });
    return data.types_participant;
}

export function useGetParticipantTypes(params?: ParticipantTypesDTO) {
    const query = useQuery({
        queryKey: ["participant-types", { params }],
        queryFn: () => getParticipantTypes(params),
    });

    return query;
}
