import { Spinner } from "@resamare/ui";
import { useParams } from "react-router-dom";
import {
    NavibusSearch,
    getItineraryFromData,
    getNavibusSchema,
    useClientConfig,
    useDefaultConfigSearch,
    useGetParticipantTypes,
    useGetProductSections,
    useGetProducts,
    useSearchContext,
} from "@resamare/widgets-shared";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { dt } from "@resamare/functions";
import { NavibusFormView } from "../components/NavibusFormView";

export function NavibusFormLogic() {
    const { config } = useClientConfig();
    const queryClient = useQueryClient();
    const { setSearch } = useSearchContext<NavibusSearch>();
    const { productTypeId } = useParams() as { productTypeId: string };
    const participantTypesQuery = useGetParticipantTypes({ productTypeId });
    const productsQuery = useGetProducts({ productTypeId });
    const productSectionsQuery = useGetProductSections(productsQuery.data?.[0].id);
    const isFetchingSessions = useIsFetching({ queryKey: ["navibus-sessions"] });
    const defaultSearch = useDefaultConfigSearch();

    if (
        participantTypesQuery.isLoading ||
        productsQuery.isLoading ||
        productSectionsQuery.isLoading
    ) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data || !productsQuery.data || !productSectionsQuery.data) {
        return null;
    }

    const schema = getNavibusSchema(participantTypesQuery.data, productSectionsQuery.data);

    const handleOnSubmit = (data: NavibusSearch) => {
        queryClient.removeQueries({ queryKey: ["navibus-sessions"] });
        const itinerary = getItineraryFromData(data, productSectionsQuery.data);
        setSearch({ ...data, itinerary });
    };

    return (
        <NavibusFormView
            defaultValues={{ produit_id: productsQuery.data[0].id, ...defaultSearch }}
            isLoading={isFetchingSessions > 0}
            maxDate={dt.fromISO(config.date_fin)}
            minDate={dt.fromISO(dt.removeIsoOffset(config.date_debut))}
            onSubmit={handleOnSubmit}
            participantTypes={participantTypesQuery.data}
            productSections={productSectionsQuery.data}
            schema={schema}
        />
    );
}
