/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    FieldValues,
    FormProvider,
    SubmitErrorHandler,
    SubmitHandler,
    useFormContext,
} from "react-hook-form";
import { isElementFocusable, slug } from "@resamare/functions";
import { ObjectSchema } from "yup";
import { RefObject, useEffect, useMemo } from "react";
import { Divider } from "../../General";
import { Stack } from "../../Layout";
import { Form } from "../Form/Form";
import { FormData } from "./types";
import { buildYupSchema } from "./validation/yup-builder";
import { FormBuilderSection } from "./FormBuilderSection";
import { SECTION_PREFIX } from "./config";
import { useForm } from "../Form/useForm";

type FormBuilderProps<FormValues extends FieldValues> = {
    children?: React.ReactNode;
    data: FormData;
    formRef?: RefObject<HTMLFormElement>;
    onMount?: (schema: ObjectSchema<FormValues>) => void;
    onSubmit?: SubmitHandler<FormValues>;
};

export function FormBuilder<FormValues extends FieldValues>({
    children,
    data,
    formRef,
    onMount,
    onSubmit,
}: FormBuilderProps<FormValues>) {
    const schema = useMemo(() => buildYupSchema<FormValues>(data), [data]);
    const methods = useForm({ schema });
    const contextMethods = useFormContext<FormValues>();

    const fields = data.sections.map((section, index) => (
        <Stack key={slug(`${SECTION_PREFIX}-${section.id}`)} gap={5}>
            <FormBuilderSection section={section} />
            {index !== data.sections.length - 1 ? <Divider /> : null}
        </Stack>
    ));

    useEffect(() => {
        if (onMount) {
            onMount(schema);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schema]);

    if (contextMethods) {
        return (
            <FormBuilderComponent formRef={formRef} onSubmit={onSubmit}>
                {children}
                {fields}
            </FormBuilderComponent>
        );
    }

    return (
        <FormProvider {...methods}>
            <FormBuilderComponent formRef={formRef} onSubmit={onSubmit}>
                {children}
                {fields}
            </FormBuilderComponent>
        </FormProvider>
    );
}

interface FormBuilderComponentProps<FormValues extends FieldValues> {
    children: React.ReactNode;
    formRef?: RefObject<HTMLFormElement>;
    onSubmit?: SubmitHandler<FormValues>;
}

function FormBuilderComponent<FormValues extends FieldValues>({
    children,
    formRef,
    onSubmit,
}: FormBuilderComponentProps<FormValues>) {
    const handleOnErrors: SubmitErrorHandler<FormValues> = (errors) => {
        const element = document.querySelector<HTMLInputElement>(`#${Object.keys(errors)[0]}`);
        element?.scrollIntoView({ block: "center", behavior: "smooth" });
        if (element && isElementFocusable(element)) {
            element.focus();
        }
    };

    return (
        <Form formRef={formRef} onError={handleOnErrors} onSubmit={onSubmit}>
            <Stack gap={5}>{children}</Stack>
        </Form>
    );
}
