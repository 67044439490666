import { Liaison } from "@resamare/widgets-shared";
import { dt } from "@resamare/functions";
import { Card, Divider, Stack, Typography } from "@resamare/ui";
import { NavibusCardFooter } from "./NavibusCardFooter";

import styles from "./NavibusCard.module.scss";

type NavibusCardProps = {
    children: React.ReactNode;
    liaisons: Liaison[];
    onClick: () => void;
    total?: number;
};

export function NavibusCard({ children, liaisons, onClick, total }: NavibusCardProps) {
    const firstLiaison = liaisons[0];
    const lastLiaison = liaisons[liaisons.length - 1];

    const startDate = dt.fromISO(dt.removeIsoOffset(firstLiaison.date_depart));
    const endDate = dt.fromISO(dt.removeIsoOffset(lastLiaison.date_fin));

    return (
        <Card className={styles["navibus-card"]} variant="outlined">
            <Stack className={styles["navibus-card__details"]} gap={2}>
                <div>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="title-xs">{dt.formatLocaleTime(startDate)}</Typography>
                        <Stack className={styles["navibus-card__duration"]}>
                            {liaisons.length > 1 ? (
                                <Typography variant="text-sm">
                                    {liaisons.length - 1} escale
                                </Typography>
                            ) : null}
                        </Stack>
                        <Typography align="right" variant="title-xs">
                            {dt.formatLocaleTime(endDate)}
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography align="right" variant="text-sm">
                            {firstLiaison.lieux_depart[0].label}
                        </Typography>
                        <Typography align="right" variant="text-sm">
                            {lastLiaison.lieux_destination[0].label}
                        </Typography>
                    </Stack>
                </div>
                {children}
            </Stack>
            <div className={styles["navibus-card__divider"]}>
                <Divider />
            </div>
            <NavibusCardFooter onClick={onClick} total={total} />
        </Card>
    );
}
