import { useQuery } from "@tanstack/react-query";
import { Config } from "@resamare/models";
import { apiClient } from "../../../config";

export type ParticipantTypesDTO = {
    productId?: string;
    productTypeId?: string;
};

export async function getConfig(): Promise<Config> {
    try {
        const response = await apiClient.get("/config");
        return response.data;
    } catch (error) {
        return {};
    }
}

export function useGetConfig() {
    const query = useQuery({
        queryKey: ["config"],
        queryFn: getConfig,
    });

    return query;
}
