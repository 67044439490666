import { Card, LocationIcon, Stack, TripOriginIcon, Typography } from "@resamare/ui";
import { Departure, Liaison } from "@resamare/widgets-shared";
import { DateFormat, dt } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";

import styles from "./LiaisonResumeCard.module.scss";

type LiaisonResumeCardProps = {
    departure: Departure;
    destination: Departure;
    liaison: Liaison;
    type: "outbound" | "return";
};

export function LiaisonResumeCard({
    departure,
    destination,
    liaison,
    type,
}: LiaisonResumeCardProps) {
    const { t } = useTranslation();

    const startDate = dt.fromISO(dt.removeIsoOffset(liaison.date_depart));
    const endDate = dt.fromISO(dt.removeIsoOffset(liaison.date_fin));

    return (
        <Card className={styles["liaison-resume"]} variant="outlined">
            <Stack gap={2}>
                <Typography variant="text-sm">
                    {type === "outbound" ? t("Trajet aller") : t("Trajet retour")}
                </Typography>

                <Stack direction="row" gap={2}>
                    <TripOriginIcon />
                    <Stack className={styles["liaison-resume__departure"]}>
                        <Typography fontWeight={600}>{departure.label}</Typography>
                        <Typography>
                            {dt.formatLocale(startDate, DateFormat.DATE_MED)},{" "}
                            {dt.formatLocaleTime(startDate)}
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction="row" gap={2}>
                    <LocationIcon />
                    <Stack>
                        <Typography fontWeight={600}>{destination.label}</Typography>
                        <Typography>
                            {dt.formatLocale(endDate, DateFormat.DATE_MED)},{" "}
                            {dt.formatLocaleTime(endDate)}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
}
