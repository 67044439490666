/* eslint-disable react/jsx-sort-props */
import { Navigate, Route, Routes } from "react-router-dom";
import { SearchLayout } from "@/layouts/SearchLayout/SearchLayout";
import { PackagesPage } from "../pages";

export function PackagesRoutes() {
    return (
        <Routes>
            <Route element={<SearchLayout />}>
                <Route path="/:productTypeId" element={<PackagesPage />} />
                <Route path="*" element={<Navigate to="." />} />
            </Route>
        </Routes>
    );
}
