import { FormHidden, Stack, useForm } from "@resamare/ui";

import { useState } from "react";
import { FormProvider } from "react-hook-form";
import { ObjectSchema } from "yup";
import { AxiosError } from "axios";
import { dt } from "@resamare/functions";
import { Session } from "../../../models";
import {
    DepartureLocationOption,
    DepartureTimeOption,
    ExcursionOptionsValues,
    getExcursionOptionsSchema,
} from "../../activity-types/excursion";
import { useGetAdditionnalOptionsForm } from "../../sessions";
import { useCreateCartLine } from "../../cart";
import { OptionsFormModal } from "./OptionsFormModal";
import { AdditionalOptions } from "./AdditionalOptions";
import { useGetOptions } from "../api/getOptions";
import { ContactInformations } from "./ContactInformations";

type OptionsModuleProps = {
    defaultValues: {
        hour: string;
        lieu_id: string;
        participants: Record<string, number>;
        session_id: number;
    };
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    sessions: Session[];
};

export function OptionsModule({
    defaultValues,
    isOpen,
    onClose,
    onSuccess,
    sessions,
}: OptionsModuleProps) {
    const [isAddOptionsOpen, setIsAddOptionsOpen] = useState(false);
    const [schema, setSchema] = useState(getExcursionOptionsSchema());
    const [lineId, setLineId] = useState<number | null>(null);

    const optionsQuery = useGetOptions({
        date: dt.fromISO(dt.removeIsoOffset(sessions[0].date_depart)),
    });

    const form = useForm({ defaultValues, options: { shouldUnregister: true }, schema });

    const selectedHour = form.watch("hour", defaultValues["hour"]);
    const selectedSession = sessions.find(({ heure_depart: { value } }) => value === selectedHour);

    const addOptionsQuery = useGetAdditionnalOptionsForm();
    const onCreateLine = async (id: unknown) => {
        if (typeof id !== "number") {
            onSuccess();
            return;
        }
        if (!selectedSession) throw new Error("No selected session");
        const params = { lineId: id, participants: defaultValues["participants"] };
        try {
            await addOptionsQuery.getAdditionnalOptions(selectedSession.id, params);
            onClose();
            setLineId(id);
            setIsAddOptionsOpen(true);
        } catch (error) {
            if (!(error instanceof AxiosError) || error?.response?.status !== 404) throw error;
            // Si on reçoit une 404, c'est que des lignes de panier ont été fusionnées.
            // On ne propose pas les options de type pourcentage dans ce cas là.
            onSuccess();
        }
        form.reset();
    };

    const createLineMutation = useCreateCartLine();
    const handleOnSubmit = async (formData: ExcursionOptionsValues) => {
        createLineMutation.mutate(formData, {
            onSuccess: onCreateLine,
        });
    };

    if (!selectedSession) {
        throw new Error("No selected session");
    }

    const hasUnbookableSession = !sessions.every((session) => session.dispo_vente);

    return (
        <>
            <FormProvider {...form}>
                <OptionsFormModal
                    isLoading={createLineMutation.isLoading || addOptionsQuery.isLoading}
                    isOpen={isOpen}
                    onClose={() => {
                        onClose();
                        form.reset();
                    }}
                    onMount={(optionsSchema) =>
                        setSchema(
                            schema.concat(optionsSchema) as ObjectSchema<ExcursionOptionsValues>,
                        )
                    }
                    onSubmit={handleOnSubmit}
                    options={optionsQuery.data}
                    renderBefore={() => (
                        <Stack gap={3}>
                            {hasUnbookableSession ? <ContactInformations /> : null}
                            <DepartureTimeOption sessions={sessions} />
                            <DepartureLocationOption session={selectedSession} />
                            <FormHidden name="session_id" />
                            <FormHidden name="participants" />
                        </Stack>
                    )}
                    session={selectedSession}
                />
            </FormProvider>
            <AdditionalOptions
                isOpen={isAddOptionsOpen}
                lineId={lineId}
                onClose={() => {
                    setIsAddOptionsOpen(false);
                    setLineId(null);
                }}
                onSuccess={() => {
                    setIsAddOptionsOpen(false);
                    onSuccess();
                }}
                session={selectedSession}
            />
        </>
    );
}
