import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config";
import { Package } from "../../../models";

type GetPackagesDTO = {
    type_produit?: number;
};

export async function getPackages(params: GetPackagesDTO): Promise<Package[]> {
    const { data } = await apiClient.get("/packages", {
        params: { type_produit: params.type_produit },
    });
    return data;
}

type UseGetPackagesOptions = {
    params: GetPackagesDTO | null;
};

export function useGetPackages({ params }: UseGetPackagesOptions) {
    const query = useQuery({
        queryKey: ["packages", { ...params }],
        queryFn: () => getPackages(params as GetPackagesDTO),
        enabled: !!params,
        keepPreviousData: true,
    });

    return query;
}
