import { useEffect } from 'react';

export const useInjectStyleSheet = (href: string) => {
    useEffect(() => {
        if (import.meta.env.MODE === 'development') return;

        const isStyleSheetPresent = Array.from(
            document.querySelectorAll<HTMLLinkElement>('link[rel="stylesheet"]')
        ).some((link) => link.href.includes(href));

        if (isStyleSheetPresent) return;

        const link = document.createElement('link');
        link.href = href;
        link.rel = 'stylesheet';

        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [href]);
};
