import {
    useGetParticipantTypes,
    useSearchContext,
    useGetPackages,
    PackageSearch,
} from "@resamare/widgets-shared";
import { useParams } from "react-router-dom";
import { Alert, Spinner } from "@resamare/ui";
import { isEqual } from "@resamare/functions";
import { PackageFormUI } from "./PackageFormUI";

export function PackageForm() {
    const { productTypeId } = useParams() as { productTypeId: string };
    const { search, setSearch } = useSearchContext<PackageSearch>();

    const participantTypesQuery = useGetParticipantTypes({ productTypeId });

    const packagesQuery = useGetPackages({ params: search });

    if (participantTypesQuery.isLoading) {
        return <Spinner />;
    }

    if (!participantTypesQuery.data) {
        return <Alert severity="error">Aucun type de participant n'a pû être récupéré</Alert>;
    }

    const onSubmit = (data: PackageSearch) => {
        if (isEqual(data, search)) packagesQuery.refetch();
        setSearch(data);
    };

    return (
        <PackageFormUI
            key={productTypeId}
            defaultValues={{ type_produit: parseInt(productTypeId, 10) }}
            isLoading={packagesQuery.isFetching}
            onSubmit={onSubmit}
            participantTypes={participantTypesQuery.data}
        />
    );
}
