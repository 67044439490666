/* eslint-disable no-param-reassign */
import axios from "axios";
import { ClientConfig } from "../models";

export const apiClient = axios.create({});

export const initApiClient = (config: Partial<ClientConfig>) => {
    apiClient.defaults.baseURL = config.api;
    apiClient.defaults.headers["Resamare-Langue"] = config.langue || "fr";

    if (config.partenaireId) {
        apiClient.defaults.headers["Resamare-Partenaire-Id"] = config.partenaireId;
    }
};
