export type DataField =
    | CheckboxField
    | DateField
    | FileField
    | NumberField
    | SelectField
    | SelectMultipleField
    | TextField
    | TextAreaField;

export enum DataFieldType {
    CHECKBOX = "checkbox",
    DATE = "date",
    FILE = "file",
    NUMBER = "numerique",
    SELECT = "select",
    SELECT_MULTIPLE = "select multiple",
    TEXT = "text",
    TEXTAREA = "textarea",
}

interface BaseField {
    type: DataFieldType;
    id: string;
    label: string;
    description?: string;
    rules?: {
        required?: boolean;
    };
}

export interface CheckboxField extends BaseField {
    type: DataFieldType.CHECKBOX;
}

export interface DateField extends BaseField {
    type: DataFieldType.DATE;
}

export interface FileField extends BaseField {
    type: DataFieldType.FILE;
    rules: BaseField["rules"] & {
        mimes: string[];
        max: number;
    };
}

export interface NumberField extends BaseField {
    type: DataFieldType.NUMBER;
    max?: number;
    min?: number;
}

export interface SelectField extends BaseField {
    type: DataFieldType.SELECT;
    options: { label: string; value: string }[];
}

export interface SelectMultipleField extends BaseField {
    type: DataFieldType.SELECT_MULTIPLE;
    options: { label: string; value: string }[];
}

export interface TextField extends BaseField {
    type: DataFieldType.TEXT;
}

export interface TextAreaField extends BaseField {
    type: DataFieldType.TEXTAREA;
}
