import { useState } from "react";
import {
    ExcursionSearch,
    Product,
    Session,
    useCreateCartLine,
    useGetSessionOptions,
    useSearchContext,
} from "@resamare/widgets-shared";
import { useNavigate } from "react-router-dom";
import { ExcursionCard } from "./ExcursionCard/ExcursionCard";
import { ExcursionOptions } from "./ExcursionOptions/ExcursionOptions";

type ExcursionProps = {
    excursion: Product;
};

export function Excursion({ excursion }: ExcursionProps) {
    const navigate = useNavigate();
    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };

    const sessionOptionsQuery = useGetSessionOptions(excursion.sessions[0].id, {
        participants: search.participants,
    });

    const createLineMutation = useCreateCartLine();

    const [isOptionsOpen, setIsOptionsOpen] = useState(false);

    const handleMutationSuccess = () => {
        navigate("/checkout");
    };

    const handleOnClick = () => {
        const { sessions } = excursion;

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (shouldOpenOptions(sessionOptionsQuery.data, sessions)) {
            setIsOptionsOpen(true);
            return;
        }

        const data = {
            lieu_id: sessions[0].lieux_depart[0].value,
            participants: search.participants,
            session_id: parseInt(sessions[0].id, 10),
        };

        createLineMutation.mutate(data, { onSuccess: handleMutationSuccess });
    };

    return (
        <>
            <ExcursionCard
                excursion={excursion}
                isLoading={createLineMutation.isLoading}
                onClick={handleOnClick}
            />
            <ExcursionOptions
                isOpen={isOptionsOpen}
                onClose={() => setIsOptionsOpen(false)}
                onSuccess={handleMutationSuccess}
                product={excursion}
            />
        </>
    );
}

function shouldOpenOptions(options: object[] | undefined, sessions: Session[]) {
    const isDataUndefined = options === undefined;
    const hasDifferentDepartureTimes =
        sessions.length > 1 &&
        sessions.some(
            (session, _, [firstSession]) =>
                session.heure_depart.value !== firstSession.heure_depart.value,
        );
    const hasMultipleDepartureLocations = sessions[0]?.lieux_depart.length > 1;
    const hasOptionsData = options && options?.length > 0;

    return (
        isDataUndefined ||
        hasDifferentDepartureTimes ||
        hasMultipleDepartureLocations ||
        hasOptionsData
    );
}
