import { ProductSection } from "@resamare/models";
import { NavibusSearch } from "../../../search";

export const getItineraryFromData = (data: NavibusSearch, sections: ProductSection[]) => {
    const { itinerary } = data;
    if (itinerary.length >= 3) return itinerary;

    const [departureId, arrivalId] = itinerary;
    const firstSectionDeparture = sections[0].lieu_depart.id;

    if (departureId === firstSectionDeparture || arrivalId === firstSectionDeparture) {
        return itinerary;
    }
    if (departureId === arrivalId) {
        return [departureId, firstSectionDeparture, arrivalId];
    }
    if (parseInt(departureId, 10) > parseInt(arrivalId, 10)) {
        return [departureId, firstSectionDeparture, arrivalId];
    }

    return itinerary;
};
