import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config";
import { DepartureLocation } from "../../../models";

export type DepartureLocationsDTO = {
    productTypeId?: string;
};

export async function getDepartureLocations(
    params: DepartureLocationsDTO,
): Promise<DepartureLocation[]> {
    const { productTypeId } = params;

    const { data } = await apiClient.get("/lieux-depart", {
        params: { type_produit: productTypeId },
    });

    return data;
}

export function useGetDepartureLocations(params: DepartureLocationsDTO) {
    const query = useQuery({
        queryKey: ["departure-location", { params }],
        queryFn: () => getDepartureLocations(params),
    });

    return query;
}
