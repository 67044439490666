import React from "react";
import { Alert, BoatIcon, Stack, Typography, WalkIcon } from "@resamare/ui";
import { Liaison } from "@resamare/widgets-shared";
import { dt } from "@resamare/functions";

import styles from "./NavibusCardTripDetails.module.scss";

type NavibusCardTripDetailsProps = {
    liaisons: Liaison[];
};

export function NavibusCardTripDetails({ liaisons }: NavibusCardTripDetailsProps) {
    return (
        <Stack className={styles["trip"]} direction="row" gap={2}>
            <div className={styles["trip__bar"]} />
            <Stack className={styles["trip__steps"]} gap={2}>
                {liaisons.map((liaison, index) => (
                    <React.Fragment key={liaison.id}>
                        <Stack className={styles["trip__step"]}>
                            <div className={styles["trip__step-bar"]} />
                            <Stack direction="row" gap={2}>
                                <Typography>
                                    {dt.formatLocaleTime(
                                        dt.fromISO(dt.removeIsoOffset(liaison.date_depart)),
                                    )}
                                </Typography>
                                <Typography fontWeight={600}>
                                    {liaison.lieux_depart[0].label}
                                </Typography>
                            </Stack>
                            <Stack alignItems="center" direction="row" gap={1}>
                                <span className={styles["clock-icon"]}>
                                    <BoatIcon size="inherit" />
                                </span>
                                <Typography variant="text-sm">
                                    {dt.formatDuration(
                                        dt.getDiff(
                                            dt.fromISO(dt.removeIsoOffset(liaison.date_depart)),
                                            dt.fromISO(dt.removeIsoOffset(liaison.date_fin)),
                                        ),
                                    )}
                                </Typography>
                            </Stack>
                            <Stack direction="row" gap={2}>
                                <Typography>
                                    {dt.formatLocaleTime(
                                        dt.fromISO(dt.removeIsoOffset(liaison.date_fin)),
                                    )}
                                </Typography>
                                <Typography fontWeight={600}>
                                    {liaison.lieux_destination[0].label}
                                </Typography>
                            </Stack>
                        </Stack>
                        {index !== liaisons.length - 1 ? (
                            <Alert severity="info" showIcon={false}>
                                <Stack alignItems="center" component="span" direction="row" gap={1}>
                                    <span className={styles["clock-icon"]}>
                                        <WalkIcon size="inherit" />
                                    </span>
                                    <Typography component="span">
                                        Correspondance à {liaison.lieux_destination[0].label}
                                    </Typography>
                                </Stack>
                            </Alert>
                        ) : null}
                    </React.Fragment>
                ))}
            </Stack>
        </Stack>
    );
}
