/* eslint-disable @typescript-eslint/no-use-before-define */
import { isEmpty, storage } from "@resamare/functions";
import { useTranslation } from "@resamare/translation";
import { Button, Drawer, FormBuilder, Stack } from "@resamare/ui";

import { FieldValues } from "react-hook-form";
import { useFormSubmitter } from "@resamare/react-hooks";
import { useEffect } from "react";
import { Session } from "../../../models";
import { ExcursionSearch, useSearchContext } from "../../search";
import { useGetSessionOptionsForm } from "../../sessions";
import { useDeleteCartLine } from "../../cart";
import { useAddOptions } from "../api/AddOptions";
import { OverlayFooter, OverlayHeader } from "../../../components";
import { ModalLayout } from "../../../layouts";

type AdditionalOptionsProps = {
    isOpen: boolean;
    lineId: number | null;
    onClose: () => void;
    onSuccess: () => void;
    session: Session;
};

export function AdditionalOptions({
    isOpen,
    lineId,
    onClose,
    onSuccess,
    session,
}: AdditionalOptionsProps) {
    const { t } = useTranslation();

    const { formRef, submitForm } = useFormSubmitter();
    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };

    const params = { lineId: lineId || undefined, participants: search?.participants };
    const optionsQuery = useGetSessionOptionsForm(session.id, params);

    const deleteLineQuery = useDeleteCartLine();
    const addOptions = useAddOptions();

    if (optionsQuery.isLoading || !optionsQuery.data || !lineId) {
        return null;
    }

    if (isEmpty(optionsQuery.data.sections)) {
        return <NoAdditionalOptions isOpen={isOpen} onSuccess={onSuccess} />;
    }

    const handleOnClose = () => {
        const cartKey = storage.getWithExpiry<string>("cart");
        if (!cartKey) throw new Error("Cart not found");
        deleteLineQuery.mutate({ cartKey, lineId });
        onClose();
    };

    const handleOnSubmit = (data: FieldValues) => {
        const cartKey = storage.getWithExpiry<string>("cart");
        if (!cartKey) throw new Error("Cart not found");
        addOptions.mutate({ cartKey, lineId, options: data }, { onSuccess });
    };

    return (
        <Drawer isOpen={isOpen} maxWidth={560} onClose={handleOnClose}>
            <OverlayHeader onClose={handleOnClose}>{t("Assurance annulation")}</OverlayHeader>
            <ModalLayout>
                <FormBuilder data={optionsQuery.data} formRef={formRef} onSubmit={handleOnSubmit} />
            </ModalLayout>
            <OverlayFooter>
                <Stack direction="row" justifyContent="flex-end">
                    <Button isLoading={false} onClick={submitForm}>
                        {t("Suivant")}
                    </Button>
                </Stack>
            </OverlayFooter>
        </Drawer>
    );
}

type NoAdditionalOptionsProps = {
    isOpen: boolean;
    onSuccess: () => void;
};

function NoAdditionalOptions({ isOpen, onSuccess }: NoAdditionalOptionsProps) {
    useEffect(() => {
        if (isOpen) onSuccess();
    }, [isOpen, onSuccess]);

    return null;
}
