import { CircleMinusIcon, CirclePlusIcon, IconButton } from "../../General";
import { Stack } from "../../Layout";

import styles from "./NumberStepperField.module.scss";
import { FormInput } from "../_partials";

interface StepperProps {
    editable?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    max?: number;
    min?: number;
    name: string;
    onChange: (value: number) => void;
    step?: number;
    value: number;
}

export function Stepper({
    editable = false,
    inputRef,
    max,
    min = 0,
    name,
    onChange,
    step = 1,
    value,
}: StepperProps) {
    const handleDecrement = () => {
        if (value <= min) return;
        onChange(value - step);
    };

    const handleIncrement = () => {
        if (max && value >= max) return;
        onChange(value + step);
    };

    const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!editable) return;
        const int = parseInt(e.target.value, 10);

        if (Number.isNaN(int)) {
            onChange(0);
            return;
        }

        onChange(int);
    };

    const handleOnFocusInput = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!editable) return;
        e.target.select();
    };

    return (
        <Stack alignItems="center" className={styles["stepper"]} direction="row">
            <IconButton
                aria-label="decrement"
                disabled={value === 0 || (min ? value <= min : false)}
                edge="start"
                onClick={handleDecrement}
            >
                <CircleMinusIcon size="large" />
            </IconButton>
            <div>
                <FormInput
                    className={styles["stepper-fieldset"]}
                    inputClassName={styles["stepper-input"]}
                    inputRef={inputRef}
                    name={name}
                    onChange={handleOnInputChange}
                    onFocus={handleOnFocusInput}
                    readOnly={!editable}
                    value={value.toString()}
                />
            </div>
            <IconButton
                aria-label="increment"
                disabled={max !== undefined ? value >= max : false}
                onClick={handleIncrement}
            >
                <CirclePlusIcon size="large" />
            </IconButton>
        </Stack>
    );
}
