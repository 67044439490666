import { ButtonLink, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";

import styles from "./Overlay.module.scss";

type OverlayHeaderProps = {
    children: React.ReactNode;
    onClose?: () => void;
};

export function OverlayHeader({ children, onClose }: OverlayHeaderProps) {
    const { t } = useTranslation();

    return (
        <div className={`${styles["overlay"]} ${styles["overlay-header"]}`}>
            {typeof children === "string" ? (
                <div className={styles["overlay-header__title"]}>
                    <Typography variant="title-xs">{children}</Typography>
                </div>
            ) : (
                children
            )}
            {onClose ? <ButtonLink onClick={onClose}>{t("Fermer")}</ButtonLink> : null}
        </div>
    );
}
