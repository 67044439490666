import { ElementType } from "react";
import { FormHelperText as MuiFormHelperText } from "@mui/material";

import { Typography } from "../../../General";

import Styles from "./FormHelperText.module.scss";

export interface FormHelperTextProps {
    children: string;
    component?: ElementType;
}

export function FormHelperText({ children, component = "p" }: FormHelperTextProps) {
    return (
        <MuiFormHelperText className={Styles.FormHelperText} component={component} error={false}>
            <Typography component="span" variant="helper-text">
                {children}
            </Typography>
        </MuiFormHelperText>
    );
}
