import { ErrorMessage } from "@hookform/error-message";
import { FormErrorMessage } from "./FormErrorMessage";

type FormErrorProps = {
    name: string;
};

export function FormError({ name }: FormErrorProps) {
    return (
        <ErrorMessage
            name={name}
            render={({ message }) => {
                if (!message) return null;
                return <FormErrorMessage>{message}</FormErrorMessage>;
            }}
        />
    );
}
