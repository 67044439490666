import { Stack, Typography } from "@resamare/ui";
import { Package, usePrefetchDatesAvailable } from "@resamare/widgets-shared";
import { useTranslation } from "@resamare/translation";
import { useCallback, useState } from "react";
import { flat } from "@resamare/functions";
import { PackageComposer } from "./PackageComposer/PackageComposer";
import { PackageItem } from "./PackageItem";

type PackagesListProps = {
    packages: Package[];
};

export function PackagesList({ packages }: PackagesListProps) {
    const { t } = useTranslation();

    const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);

    const handleClick = useCallback((packageItem: Package) => {
        setSelectedPackage(packageItem);
    }, []);

    const productIds = flat(packages.map((pkg) => pkg.produits.map((product) => product.id)));
    usePrefetchDatesAvailable(productIds);

    return (
        <Stack gap={3}>
            <Typography fontWeight={600}>{t("résultat", { count: packages.length })}</Typography>

            {packages.map((packageItem) => (
                <PackageItem key={packageItem.id} onClick={handleClick} packageItem={packageItem} />
            ))}
            <PackageComposer
                onClose={() => setSelectedPackage(null)}
                packageItem={selectedPackage}
            />
        </Stack>
    );
}
