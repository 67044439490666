import {
    LiaisonSearch,
    Section,
    SelectedLiaison,
    TripType,
    getOutboundParams,
    getReturnParams,
    useClientConfig,
    useSearchContext,
} from "@resamare/widgets-shared";

import { useEffect, useState } from "react";
import { Stack } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { dt } from "@resamare/functions";
import { LiaisonChoice } from "../components/LiaisonChoice";
import { LiaisonResume } from "../components/LiaisonResume";

export function LiaisonsPage() {
    const { config } = useClientConfig();
    const { t } = useTranslation();
    const { search: formSearch } = useSearchContext<LiaisonSearch>();
    const [search, setSearch] = useState(formSearch);

    const [selectedLiaisons, setSelectedLiaisons] = useState<{
        outbound: SelectedLiaison | null;
        return: SelectedLiaison | null;
    }>({ outbound: null, return: null });

    useEffect(() => {
        setSearch(formSearch);
        setSelectedLiaisons({ outbound: null, return: null });
    }, [formSearch]);

    if (!formSearch || !search) return null;

    return (
        <Section>
            <Stack gap={6}>
                <LiaisonChoice
                    isCompleted={selectedLiaisons.outbound !== null}
                    label={t("Choisissez votre aller")}
                    minDate={dt.fromISO(dt.removeIsoOffset(config.date_debut))}
                    onDateChange={(d) => setSearch({ ...search, dates: [d, search?.dates[1]] })}
                    onEdit={() => setSelectedLiaisons({ outbound: null, return: null })}
                    onSelect={(data) =>
                        setSelectedLiaisons({ ...selectedLiaisons, outbound: data })
                    }
                    search={getOutboundParams(search)}
                    step={1}
                />

                {formSearch["trip-type"] === TripType.ROUNDTRIP ? (
                    <LiaisonChoice
                        isCompleted={selectedLiaisons.return !== null}
                        isDisabled={selectedLiaisons.outbound === null}
                        label={t("Choisissez votre retour")}
                        minDate={selectedLiaisons.outbound?.date_fin}
                        onDateChange={(d) => setSearch({ ...search, dates: [search?.dates[0], d] })}
                        onEdit={() => setSelectedLiaisons((prev) => ({ ...prev, return: null }))}
                        onSelect={(data) =>
                            setSelectedLiaisons({ ...selectedLiaisons, return: data })
                        }
                        search={getReturnParams(search, selectedLiaisons.outbound)}
                        step={2}
                    />
                ) : null}

                <LiaisonResume
                    liaisons={Object.values(selectedLiaisons).filter((v) => v) as SelectedLiaison[]}
                />
            </Stack>
        </Section>
    );
}
