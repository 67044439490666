import { Button, Card, Divider, Image, Stack } from "@resamare/ui";
import { useState } from "react";
import { useTranslation } from "@resamare/translation";
import { Package } from "@resamare/widgets-shared";
import { PackageCardDetails } from "./PackageCardDetails";
import { PackageCardContent } from "./PackageCardContent";
import { PackageCardFooter } from "./PackageCardFooter";

import styles from "./PackageCard.module.scss";

type PackageCardProps = {
    onClick: (packageItem: Package) => void;
    packageItem: Package;
    priceFrom?: number;
};

export function PackageCard({ onClick, packageItem, priceFrom }: PackageCardProps) {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const openDetails = () => setIsOpen(true);
    const closeDetails = () => setIsOpen(false);

    return (
        <Card className={styles["package-card"]}>
            <div className={styles["package-card__image"]}>
                <Image src={packageItem.images[0]} />

                <div className={styles["package-card__button"]}>
                    <Button onClick={openDetails} size="small" variant="outlined">
                        {t("Plus d'infos")}
                    </Button>
                </div>
            </div>
            <Stack className={styles["package-card__content"]} gap={2}>
                <PackageCardContent openDetails={openDetails} packageItem={packageItem} />
                <Divider />
                <PackageCardFooter
                    onClick={onClick}
                    packageItem={packageItem}
                    priceFrom={priceFrom}
                />
            </Stack>

            <PackageCardDetails isOpen={isOpen} onClose={closeDetails} packageItem={packageItem} />
        </Card>
    );
}
