import {
    ExcursionOptionsValues,
    ExcursionSearch,
    Session,
    usePrefetchSessionOptionsForm,
    useSearchContext,
} from "@resamare/widgets-shared";
import { useEffect } from "react";
import { SubmitHandler } from "react-hook-form";
import { PackageChoiceOptions } from "./PackageChoiceOptions";

type PackageChoiceSessionProps = {
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    sessions: Session[];
};

export function PackageChoiceSession({ onSubmit, sessions }: PackageChoiceSessionProps) {
    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };
    const sessionIds = sessions.map((session) => session.id);

    const prefetchOptions = usePrefetchSessionOptionsForm(sessionIds, {
        participants: search.participants,
    });

    useEffect(() => {
        prefetchOptions();
    }, [prefetchOptions]);

    const firstBookableSession = sessions.find((session) => session.dispo_vente) || sessions[0];

    return (
        <PackageChoiceOptions
            defaultSession={firstBookableSession}
            defaultValues={{
                session_id: parseInt(firstBookableSession.id, 10),
                hour: firstBookableSession.heure_depart.value,
                lieu_id: firstBookableSession.lieux_depart[0].value.toString(),
                participants: search?.participants,
            }}
            onSubmit={onSubmit}
            sessions={sessions}
        />
    );
}
