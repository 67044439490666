import { ProductType } from "@resamare/widgets-shared";
import { TabLink, TabsList } from "@resamare/ui";
import { useParams } from "react-router-dom";
import { useUrlActivityType } from "../hooks/useUrlActivityType";

type NavigationProps = {
    onChange: () => void;
    productTypes: ProductType[];
};

export function Navigation({ onChange, productTypes }: NavigationProps) {
    const { productTypeId } = useParams();

    const activityType = useUrlActivityType();

    if (productTypes.length === 1) {
        return null;
    }

    return (
        <TabsList onChange={onChange} value={`${activityType}-${productTypeId}`}>
            {productTypes.map((type) => (
                <TabLink
                    key={type.value}
                    label={type.label}
                    to={`/${type.type_form}/${type.value}`}
                    value={`${type.type_form}-${type.value}`}
                />
            ))}
        </TabsList>
    );
}
