import {
    ExcursionOptionsValues,
    NoResultFound,
    PackageSearch,
    useGetSessions,
    useSearchContext,
} from "@resamare/widgets-shared";
import { Spinner } from "@resamare/ui";
import { flat, isEmpty, unique } from "@resamare/functions";
import { SubmitHandler } from "react-hook-form";
import { PackageChoiceSession } from "./PackageChoiceSession";

type PackageChoiceSessionsProps = {
    date: Date;
    onDateChange: (date: Date) => void;
    onSubmit: SubmitHandler<ExcursionOptionsValues>;
    productId: number;
};

export function PackageChoiceSessions({
    date,
    onDateChange,
    onSubmit,
    productId,
}: PackageChoiceSessionsProps) {
    const { search } = useSearchContext<PackageSearch>() as { search: PackageSearch };

    const searchParams = {
        date_deb: date,
        participants: search.participants,
        produit_id: productId,
    };
    const sessionsQuery = useGetSessions({ params: searchParams });

    if (sessionsQuery.isFetching) return <Spinner />;
    if (!sessionsQuery.data) return null;

    const sessionsWithoutDuplicates = unique(
        flat(sessionsQuery.data.map((product) => product.sessions)),
        (session) => session.heure_depart.value,
    );

    if (isEmpty(sessionsWithoutDuplicates)) {
        return <NoResultFound onDateClick={onDateChange} search={searchParams} />;
    }

    return <PackageChoiceSession onSubmit={onSubmit} sessions={sessionsWithoutDuplicates} />;
}
