import { useEffect } from "react";
import {
    ExcursionSearch,
    OptionsModule,
    Product,
    usePrefetchSessionOptionsForm,
    useSearchContext,
} from "@resamare/widgets-shared";

type ExcursionOptionsProps = {
    isOpen?: boolean;
    onClose: () => void;
    onSuccess: () => void;
    product: Product;
};

export function ExcursionOptions({
    isOpen = false,
    onClose,
    onSuccess,
    product,
}: ExcursionOptionsProps) {
    const { search } = useSearchContext<ExcursionSearch>() as { search: ExcursionSearch };
    const sessionIds = product.sessions.map((session) => session.id);

    const prefetchOptions = usePrefetchSessionOptionsForm(sessionIds, {
        participants: search.participants,
    });

    useEffect(() => {
        if (isOpen) prefetchOptions();
    }, [isOpen, prefetchOptions]);

    const { sessions } = product;
    const firstBookableSession = sessions.find((session) => session.dispo_vente) || sessions[0];

    return (
        <OptionsModule
            key={firstBookableSession.heure_depart.value}
            defaultValues={{
                session_id: parseInt(firstBookableSession.id, 10),
                hour: firstBookableSession.heure_depart.value,
                lieu_id: firstBookableSession.lieux_depart[0].value.toString(),
                participants: search?.participants,
            }}
            isOpen={isOpen}
            onClose={onClose}
            onSuccess={onSuccess}
            sessions={sessions}
        />
    );
}
