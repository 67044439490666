import { Divider, Form, Stack, useForm } from "@resamare/ui";
import { FormProvider } from "react-hook-form";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "@resamare/functions";
import { useIsFetching } from "@tanstack/react-query";
import { Cart } from "../../../../models";
import { useCreateReservation } from "../../../reservations";
import {
    InfosValues,
    handleInfosServerError,
    infosSchema,
} from "../../validation/informationsSchema";
import { CheckoutFormButton } from "./CheckoutFormButton";
import { UserDetailsSection } from "./UserDetailsSection";
import { ParticipantsInfosSection } from "./ParticipantsInfosSection";
import { ReservationInfosSection } from "./ReservationInfosSection";
import { DiscountCodeSection } from "./DiscountCodeSection";
import { NewsletterSection } from "./NewsletterSection";
import { TermsAndConditionsSection } from "./TermsAndConditionsSection";
import { DataField } from "../../../additional-infos/types";
import {
    fromDataToDynamicFields,
    getReservationInfosSchema,
    getParticipantsInfosSchema,
} from "../../../additional-infos";
import { createAndSubmitForm } from "../../utils";
import { useDeleteCartKey } from "../../../cart";
import { PaymentMethodSection } from "./PaymentMethodSection";

type CheckoutFormProps = {
    cart: Cart;
    participantsInfos: DataField[];
    reservationInfos: DataField[];
};

type ExtendedInfosValues = InfosValues & {
    "informations-participants": {
        [key: string]: string;
    };
    "informations-supplementaires": {
        [key: string]: string;
    };
};

export function CheckoutForm({ cart, participantsInfos, reservationInfos }: CheckoutFormProps) {
    const navigate = useNavigate();
    const reservationFields = fromDataToDynamicFields(reservationInfos, {
        prefix: "informations-supplementaires",
    });

    const participantfields = fromDataToDynamicFields(participantsInfos);

    const participantsInfosSchema = getParticipantsInfosSchema(participantfields, cart);
    const reservationInfosSchema = getReservationInfosSchema(reservationFields);
    const schema = infosSchema.concat(participantsInfosSchema).concat(reservationInfosSchema);

    const formRef = useRef<HTMLFormElement | null>(null);
    const form = useForm({
        defaultValues: { isSame: true },
        options: { shouldUnregister: true, shouldFocusError: true },
        schema,
    });

    const deleteCartMutation = useDeleteCartKey();
    const hasInputFile = reservationInfos.some((input) => input.type === "file");
    const reservationMutation = useCreateReservation(hasInputFile, {
        onError: (error) => handleInfosServerError(error, form.setError, formRef),
        onSuccess: (data) => {
            if (data.form) createAndSubmitForm(data.form);
            if (data.message) navigate("/checkout/order-confirmation", { state: data });
            deleteCartMutation.mutate();
        },
    });

    const isFetchingCart = Boolean(useIsFetching({ queryKey: ["cart"] }));
    const handleOnSubmit = (data: ExtendedInfosValues) => {
        reservationMutation.mutate(data);
    };

    return (
        <FormProvider {...form}>
            <Form formRef={formRef} onSubmit={handleOnSubmit}>
                <Stack gap={5}>
                    <UserDetailsSection />
                    <Divider />
                    <ParticipantsInfosSection cart={cart} fields={participantsInfos} />
                    <Divider />
                    {!isEmpty(reservationFields) ? (
                        <>
                            <ReservationInfosSection fields={reservationFields} />
                            <Divider />
                        </>
                    ) : null}
                    <DiscountCodeSection cart={cart} />
                    <Divider />
                    <NewsletterSection />
                    <Divider />

                    <Stack gap={3}>
                        <PaymentMethodSection />
                        <TermsAndConditionsSection />
                    </Stack>

                    <CheckoutFormButton
                        cart={cart}
                        isLoading={reservationMutation.isLoading || isFetchingCart}
                    />
                </Stack>
            </Form>
        </FormProvider>
    );
}
