import { FormHelperText, FormLabel } from "../_partials";
import { Stepper } from "./Stepper";
import { Stack } from "../../Layout";
import { TextFieldProps } from "../FormText/TextField";

export interface NumberStepperFieldProps
    extends Pick<TextFieldProps, "helperText" | "inputRef" | "label" | "name"> {
    editable?: boolean;
    max?: number;
    min?: number;
    onChange: (value: number) => void;
    step?: number;
    value: number;
}

export function NumberStepperField({
    editable,
    helperText,
    inputRef,
    label,
    max,
    min = 0,
    name,
    onChange,
    step,
    value,
}: NumberStepperFieldProps) {
    return (
        <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between">
            <div>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </div>
            <Stepper
                editable={editable}
                inputRef={inputRef}
                max={max}
                min={min}
                name={name}
                onChange={onChange}
                step={step}
                value={value}
            />
        </Stack>
    );
}
