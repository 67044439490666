import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Option } from "../../options/types";
import { ExcursionSearch } from "../../search";
import { apiClient } from "../../../config";

type GetOptionsDTO = {
    participants: ExcursionSearch["participants"];
};

async function getOptions(sessionId: string, params: GetOptionsDTO): Promise<Option[]> {
    const { data } = await apiClient.get(`sessions/${sessionId}/options`, {
        params: {
            participants: JSON.stringify(params.participants),
        },
    });
    return data;
}

export function useGetSessionOptions(sessionId: string, params: GetOptionsDTO) {
    const queryKey = ["options", { sessionId, ...params }];

    const query = useQuery({
        queryKey,
        queryFn: () => getOptions(sessionId, params),
        enabled: !!sessionId,
    });

    return query;
}

export function usePrefetchSessionOptions(sessionIds: string[], params: GetOptionsDTO) {
    const queryClient = useQueryClient();

    const prefetchOptions = () => {
        sessionIds.forEach((sessionId) => {
            const queryKey = ["options", { sessionId, ...params }];
            queryClient.prefetchQuery({
                queryKey,
                queryFn: () => getOptions(sessionId, params),
            });
        });
    };

    return prefetchOptions;
}
