import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from "@mui/material";

import Styles from "./Drawer.module.scss";

interface DrawerProps extends Pick<MuiDrawerProps, "children" | "onClose"> {
    isOpen: boolean;
    maxHeight?: `${number}%` | number;
    maxWidth?: `${number}%` | number;
    minHeight?: `${number}%` | number;
    position?: "bottom" | "right";
}

export function Drawer({
    children,
    isOpen,
    maxHeight,
    maxWidth,
    minHeight,
    onClose,
    position = "right",
}: DrawerProps) {
    return (
        <MuiDrawer
            PaperProps={{
                style: { width: "100%", maxHeight, maxWidth, minHeight },
                className: `${Styles[`drawer__content`]} ${
                    Styles[`drawer__content${`--is-${position}`}`]
                }`,
            }}
            anchor={position}
            onClose={onClose}
            open={isOpen}
        >
            {children}
        </MuiDrawer>
    );
}
