import {
    Liaison as ILiaison,
    LiaisonSearch,
    SelectedLiaison,
    useGetSessionOptions,
    useSearchContext,
} from "@resamare/widgets-shared";
import { useState } from "react";
import { dt } from "@resamare/functions";
import { LiaisonCard } from "./LiaisonCard/LiaisonCard";
import { LiaisonOptions } from "./LiaisonOptions";

type LiaisonProps = {
    liaison: ILiaison;
    onSelect: (data: SelectedLiaison) => void;
};

export function Liaison({ liaison, onSelect }: LiaisonProps) {
    const { search } = useSearchContext<LiaisonSearch>() as { search: LiaisonSearch };

    const optionsQuery = useGetSessionOptions(liaison.id, {
        participants: search.participants,
    });

    const [isOpen, setIsOpen] = useState(false);
    const closeOptions = () => setIsOpen(false);
    const openOptions = () => setIsOpen(true);

    const handleOnClick = () => {
        if (
            optionsQuery.data === undefined ||
            liaison.lieux_depart.length > 1 ||
            optionsQuery.data.length > 0
        ) {
            openOptions();
            return;
        }

        const data = {
            date_fin: dt.fromISO(dt.removeIsoOffset(liaison.date_fin)),
            lieu_id: liaison.lieux_depart[0].value.toString(),
            participants: search.participants,
            session_id: parseInt(liaison.id, 10),
            prix_apartirde: liaison.prix_apartirde || liaison.prix_total,
        };

        onSelect(data);
    };

    return (
        <>
            <LiaisonCard liaison={liaison} onClick={handleOnClick} />
            <LiaisonOptions
                key={isOpen.toString()}
                isOpen={isOpen}
                liaison={liaison}
                onClose={closeOptions}
                onSelect={onSelect}
            />
        </>
    );
}
