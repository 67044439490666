import { i18n } from "i18next";

export const ageRangeFormatter = (
    value: {
        min?: number;
        max?: number;
    },
    instance: i18n,
): string => {
    const { min, max } = value;

    if ((min === 0 && max === undefined) || (min === undefined && max === undefined)) {
        return "";
    }

    if (min && max === undefined) {
        return instance.t("plus de {{ min }} ans", { min });
    }

    if (max && min === 0) {
        return instance.t("moins de {{max}} ans", { max });
    }

    if (max === min) {
        return instance.t("{{max}} ans", { max });
    }

    return instance.t("de {{min}} à {{max}} ans", { min, max });
};
