import { useMutation, useQueryClient } from "@tanstack/react-query";
import { storage } from "@resamare/functions";
import { CART_KEY } from "../constants";

export async function deleteCartKey() {
    storage.remove(CART_KEY);
}

export function useDeleteCartKey() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteCartKey,
        onSuccess: () => queryClient.removeQueries({ queryKey: ["cart"] }),
    });

    return mutation;
}
