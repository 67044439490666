import { Drawer, Image, Stack, Typography } from "@resamare/ui";
import { useTranslation } from "@resamare/translation";
import { ModalLayout, OverlayHeader, Product } from "@resamare/widgets-shared";
import { removeHtml } from "@resamare/functions";
import { Slide, Slider } from "@/components/index";

type ExcursionCardDetailsProps = {
    excursion: Product;
    isOpen: boolean;
    onClose: () => void;
};

export function ExcursionCardDetails({ excursion, isOpen, onClose }: ExcursionCardDetailsProps) {
    const { t } = useTranslation();
    return (
        <Drawer isOpen={isOpen} maxWidth={600} onClose={onClose}>
            <OverlayHeader onClose={onClose}>{excursion.nom}</OverlayHeader>
            <div>
                {excursion.images.length > 1 ? (
                    <Slider autoHeight>
                        {excursion.images.map((image) => (
                            <Slide key={image}>
                                <Image src={image} />
                            </Slide>
                        ))}
                    </Slider>
                ) : (
                    <Image src={excursion.images[0]} />
                )}
            </div>
            <ModalLayout>
                <Stack gap={4}>
                    <Stack gap={0.5}>
                        <Typography fontWeight={600}>{t("Programme")}</Typography>
                        <Typography>{removeHtml(excursion.description_courte)}</Typography>
                        <Typography component="div">
                            <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: excursion.description,
                                }}
                            />
                        </Typography>
                    </Stack>
                </Stack>
            </ModalLayout>
        </Drawer>
    );
}
