import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../config";

import { Product } from "../../../models";

export async function getProduct(productId: string): Promise<Product> {
    const response = await apiClient.get(`/produits/${productId}`);
    return response.data;
}

export function useGetProduct(productId: string) {
    const query = useQuery({
        queryKey: ["products", productId],
        queryFn: () => getProduct(productId),
    });

    return query;
}
