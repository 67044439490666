import { Card, DynamicField, Stack } from "@resamare/ui";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "@resamare/translation";
import { fromDataToDynamicFields } from "../utils";
import { DataField } from "../types";

import styles from "./ParticipantInfosCard.module.scss";

type ParticipantInfosCardProps = {
    data: DataField[];
    prefix: string;
    title: string;
    watchName?: boolean;
};

export function ParticipantInfosCard({
    data,
    prefix,
    title,
    watchName = true,
}: ParticipantInfosCardProps) {
    const { t } = useTranslation();

    const { setValue, getFieldState } = useFormContext();

    const fields = fromDataToDynamicFields(data, { prefix });

    const nameField = fields.find((field) => field.label.toLowerCase() === t("Nom").toLowerCase());

    const name = useWatch({ name: "informations.nom" });

    useEffect(() => {
        if (!nameField || !watchName) return;
        const { isDirty } = getFieldState(nameField?.name);
        if (isDirty) return;
        setValue(nameField.name, name);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    return (
        <Card className={styles["participant-card"]}>
            <Stack gap={1}>
                {title}
                {fields.map((field) => (
                    <DynamicField key={field.name} data={field} />
                ))}
            </Stack>
        </Card>
    );
}
