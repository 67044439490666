import { objectToFormData, storage } from "@resamare/functions";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../config/api-client";
import { MutationConfig } from "../../../utils/react-query-utils";

type CreateReservationDTO = {
    informations: {
        prenom: string;
        nom: string;
        email: string;
        phone: {
            indicatif: string;
            telephone: string;
        };
        langue: "français" | "anglais";
        newsletter: boolean;
    };
    "informations-participants"?: {
        [key: string]: string;
    };
    "informations-supplementaires"?: {
        [key: string]: string;
    };
    "methode-paiement"?: string;
};

export async function createReservation(
    request: CreateReservationDTO,
    hasInputFile: boolean = false,
): Promise<{ form?: string; message?: string }> {
    const cartKey = storage.getWithExpiry<string>("cart");

    if (!cartKey) {
        throw new Error("Il ne semble pas y avoir de panier en cours");
    }

    const { informations } = request;

    const data = {
        informations: {
            ...informations,
            cgv_perso: true,
            indicatif: informations.phone.indicatif,
            telephone: informations.phone.telephone,
        },
        "informations-participants": { ...request["informations-participants"] },
        "informations-supplementaires": { ...request["informations-supplementaires"] },
        "methode-paiement": request["methode-paiement"],
    };

    const payload = hasInputFile ? objectToFormData(data) : data;

    const response = await apiClient.post(`/reservation/${cartKey}`, payload);

    return response.data;
}

export function useCreateReservation(
    hasInputFile: boolean = false,
    config?: MutationConfig<typeof createReservation>,
) {
    const mutation = useMutation({
        mutationFn: (data: CreateReservationDTO) => createReservation(data, hasInputFile),
        ...config,
    });

    return mutation;
}
