import { useTranslation } from "@resamare/translation";
import { Link, Stack } from "@resamare/ui";
import { OrderConfirmationMessage, Section } from "@resamare/widgets-shared";

export function OrderConfirmationPage() {
    const { t } = useTranslation();

    return (
        <Section>
            <Stack alignItems="center" gap={5}>
                <OrderConfirmationMessage />
                <Link to="/">{t("Retour à la recherche")}</Link>
            </Stack>
        </Section>
    );
}
