/* eslint-disable jsx-a11y/anchor-is-valid */

import { LinkProps as MuiLinkProps, Link as MuiLink } from "@mui/material";
import { isDesktop } from "react-device-detect";
import { Typography } from "../Typography/Typography";

import styles from "./ButtonLink.module.scss";

interface ButtonLinkProps extends Pick<MuiLinkProps, "variant"> {
    children?: string | React.ReactNode;
    color?: "primary" | "error" | "inherit";
    isDisabled?: boolean;
    fullWidth?: boolean;
    isLoading?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export function ButtonLink({
    children,
    color = "primary",
    isDisabled = false,
    fullWidth = false,
    isLoading,
    onClick,
    variant = "text",
}: ButtonLinkProps) {
    return (
        <MuiLink
            className={`${styles["button-link"]} ${fullWidth ? styles["full-width"] : ""}`}
            color={color === "primary" ? "primaryLink.main" : color}
            component="button"
            disabled={isDisabled || isLoading}
            onClick={onClick}
            type="button"
            underline={isDesktop ? "hover" : "always"}
        >
            <Typography
                color={color === "primary" ? "primaryLink" : color}
                component="span"
                fontWeight={600}
                variant={variant}
            >
                {children}
            </Typography>
        </MuiLink>
    );
}
