import { Stack } from "@resamare/ui";
import { DateFormat, dt } from "@resamare/functions";
import { useEffect, useRef } from "react";
import { getOutboundParams, getReturnParams } from "@resamare/widgets-shared";
import { DayButton } from "./DayButton";

import styles from "./DaysPicker.module.scss";

type DaysPickerProps = {
    minDate?: Date;
    onChange: (date: Date) => void;
    search: ReturnType<typeof getOutboundParams | typeof getReturnParams>;
    selectedDate: Date;
};

export function DaysPicker({
    minDate = dt.now(),
    onChange,
    search,
    selectedDate,
}: DaysPickerProps) {
    const dates: Date[] = [];

    for (let i = -3; i <= 3; i += 1) {
        const date = dt.add(selectedDate, { days: i });
        dates.push(date);
    }

    const ref = useRef<HTMLDivElement>(null);
    const centerSelectedDate = () => {
        if (!ref.current) return;
        const surplusLargeur = ref.current.scrollWidth - ref.current.clientWidth;
        const positionCentree = surplusLargeur / 2;
        ref.current.scrollLeft = positionCentree;
    };
    const handleOnChange = (date: Date) => {
        onChange(date);
        centerSelectedDate();
    };

    useEffect(() => {
        centerSelectedDate();
    }, []);

    return (
        <Stack ref={ref} className={styles["days-picker"]} direction="row" gap={1}>
            {dates.map((date) => (
                <DayButton
                    key={dt.formatLocale(date, DateFormat.DATE_SHORT)}
                    date={date}
                    isDisabled={dt.isBefore(date, dt.startOf(minDate, "day"))}
                    isSelected={dt.isSame(selectedDate, date)}
                    minDate={minDate}
                    onClick={handleOnChange}
                    search={search}
                />
            ))}
        </Stack>
    );
}
