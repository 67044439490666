/* eslint-disable @typescript-eslint/no-use-before-define */
import { Alert, Button, Portal, Stack, Typography } from "@resamare/ui";
import { formatCurrency } from "@resamare/functions";
import { useIntersectionObserver } from "@resamare/react-hooks";
import { useTranslation } from "@resamare/translation";
import { AsideLayout } from "../../../../layouts";
import { Cart } from "../../../../models";

import styles from "./OrderSummaryAside.module.scss";

type OrderSummaryAsideProps = {
    cart: Cart;
    onContinueShopping: () => void;
    onGoNextStep: () => void;
};

export function OrderSummaryAside({
    cart,
    onContinueShopping,
    onGoNextStep,
}: OrderSummaryAsideProps) {
    const { t } = useTranslation();

    const total = parseFloat(cart.total_panier);

    const [ref, entry] = useIntersectionObserver({ threshold: 0 });

    return (
        <AsideLayout>
            <Stack gap={3}>
                <Stack ref={ref} gap={3}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="title-xs">{t("Montant total")}</Typography>
                        <Typography variant="title-xs">{formatCurrency(total)}</Typography>
                    </Stack>

                    <Button onClick={onGoNextStep}>{t("Passer la commande")}</Button>
                    <Button onClick={onContinueShopping} variant="outlined">
                        {t("Continuer mes achats")}
                    </Button>
                </Stack>

                <Alert severity="info" title={t("Code promo / Carte cadeau")}>
                    {t(
                        "Si vous possédez un code promo ou une carte cadeau, vous pourrez l'utiliser à l'étape suivante.",
                    )}
                </Alert>
            </Stack>

            <SummaryFixed
                isVisible={Boolean(entry ? entry.isIntersecting : true)}
                onGoNextStep={onGoNextStep}
                total={total}
            />
        </AsideLayout>
    );
}

type SummaryFixedProps = {
    isVisible: boolean;
    onGoNextStep: () => void;
    total: number;
};
function SummaryFixed({ isVisible, onGoNextStep, total }: SummaryFixedProps) {
    const { t } = useTranslation();

    return (
        <Portal container={document.querySelector(".resamare-widget-recherche")}>
            <Stack
                className={`${styles["summary-fixed"]} ${
                    isVisible ? "" : styles["summary-fixed--is-visible"]
                }`}
                gap={1}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontWeight={600}>{t("Montant total")}</Typography>
                    <Typography fontWeight={600}>{formatCurrency(total)}</Typography>
                </Stack>
                <Button onClick={onGoNextStep} size="small">
                    {t("Passer la commande")}
                </Button>
            </Stack>
        </Portal>
    );
}
