import { Select as MuiSelect } from "@mui/material";
import { isEmpty } from "@resamare/functions";
import { FormControl, FormHelperText, FormInputProps, FormLabel } from "../_partials";
import { Stack } from "../../Layout";
import { SelectMultipleItem } from "./SelectMultipleItem";
import { ChevronBottomIcon, Typography } from "../../General";

import styles from "./SelectMultipleField.module.scss";

export type SelectOption = {
    label: string;
    value: string;
};

export interface SelectMultipleFieldProps
    extends Pick<FormInputProps, "inputRef" | "name" | "placeholder"> {
    helperText?: string;
    isDisabled?: boolean;
    isError?: boolean;
    label: string;
    options: SelectOption[];
    onChange: (value: string[]) => void;
    value: string[];
}

export function SelectMultipleField({
    helperText = "",
    inputRef,
    isDisabled = false,
    isError = false,
    label,
    name,
    onChange,
    options,
    placeholder = "Aucun élément sélectionné",
    value = [],
}: SelectMultipleFieldProps) {
    return (
        <FormControl isError={isError}>
            <Stack gap={0.5}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <MuiSelect
                    IconComponent={ChevronBottomIcon}
                    className={isDisabled ? styles["select--is-disabled"] : ""}
                    disabled={isDisabled}
                    displayEmpty
                    id={name}
                    inputProps={{ className: styles["select"] }}
                    inputRef={inputRef}
                    multiple
                    name={name}
                    onChange={(e) => {
                        const newValue =
                            e.target.value === "string"
                                ? e.target.value.split(",")
                                : e.target.value;
                        onChange([...newValue]);
                    }}
                    renderValue={(selectedValues) =>
                        isEmpty(selectedValues) ? (
                            placeholder
                        ) : (
                            <Stack className={styles["select__values"]} gap={0.5}>
                                {selectedValues.map((v, index) => (
                                    <Typography key={v} className={styles["select__value"]}>
                                        {options.find((option) => option.value === v)!.label}
                                        {index !== selectedValues.length - 1 ? ", " : ""}
                                    </Typography>
                                ))}
                            </Stack>
                        )
                    }
                    value={value}
                    variant="outlined"
                >
                    {options.map((option) => (
                        <SelectMultipleItem key={option.value} value={option.value}>
                            {option.label}
                        </SelectMultipleItem>
                    ))}
                </MuiSelect>
                {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
            </Stack>
        </FormControl>
    );
}
