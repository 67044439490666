import React from "react";

import styles from "./Container.module.scss";

type ContainerProps = {
    children: React.ReactNode;
};

export function Container({ children }: ContainerProps) {
    return (
        <div className={styles["container"]}>
            <div>{children}</div>
        </div>
    );
}

export default Container;
