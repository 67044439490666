import { Spinner } from "@resamare/ui";
import { Navigate, useNavigate } from "react-router-dom";
import { isEmpty } from "@resamare/functions";
import { EmptyCartMessage, Section, useClientConfig, useGetCart } from "@resamare/widgets-shared";

export function CheckoutIndexPage() {
    const { config } = useClientConfig();
    const navigate = useNavigate();
    const cartQuery = useGetCart();

    const cartIsEmpty =
        !cartQuery.isFetching &&
        (!cartQuery.data ||
            (isEmpty(cartQuery.data.ligne_package) && isEmpty(cartQuery.data.ligne_panier)));

    if (cartQuery.isFetching) {
        return (
            <Section>
                <Spinner />
            </Section>
        );
    }

    if (cartQuery.error || cartIsEmpty) {
        return (
            <Section>
                <EmptyCartMessage onClick={() => navigate("/")} />
            </Section>
        );
    }

    return config.activer_panier ? (
        <Navigate to="/checkout/order-summary" />
    ) : (
        <Navigate to="/checkout/checkout-form" />
    );
}
