import {
    NoResultFound,
    SelectedLiaison,
    getOutboundParams,
    getReturnParams,
    useClientConfig,
    useGetLiaisons,
} from "@resamare/widgets-shared";
import { useEffect } from "react";
import { dt, isEmpty } from "@resamare/functions";
import { useScrollToRef } from "@resamare/react-hooks";
import { LiaisonsList } from "./LiaisonsList";
import { DaysPicker } from "./DaysPicker/DaysPicker";

type LiaisonChoiceProps = {
    isCompleted: boolean;
    isDisabled?: boolean;
    label: string;
    minDate?: Date;
    onDateChange: (date: Date) => void;
    onEdit: () => void;
    onSelect: (data: SelectedLiaison) => void;
    search: ReturnType<typeof getOutboundParams> | ReturnType<typeof getReturnParams>;
    step: number;
};

export function LiaisonChoice({
    isCompleted,
    isDisabled = false,
    label,
    minDate = dt.now(),
    onDateChange,
    onEdit,
    onSelect,
    search,
    step,
}: LiaisonChoiceProps) {
    const { config } = useClientConfig();

    const liaisonsQuery = useGetLiaisons({ params: isDisabled || isCompleted ? null : search });

    const scrollOffset = parseInt(config.offsetTop || "0", 10) + 32;
    const { ref, scrollToRef } = useScrollToRef({ offsetTop: -scrollOffset });
    useEffect(() => {
        if (!isEmpty(liaisonsQuery.data) && ref.current) scrollToRef();
    }, [ref, scrollToRef, liaisonsQuery.data]);

    if (!search) {
        return null;
    }

    const isSameOrAfter = dt.isSameOrAfter(search.date_deb, minDate);
    const filteredLiaisons = liaisonsQuery.data?.filter(({ date_depart }) =>
        dt.isAfter(dt.fromISO(dt.removeIsoOffset(date_depart)), minDate),
    );

    return (
        <div ref={ref}>
            <LiaisonsList
                isCompleted={isCompleted}
                isDisabled={isDisabled}
                isLoading={liaisonsQuery.isFetching}
                label={label}
                liaisons={filteredLiaisons}
                onEdit={onEdit}
                onSelect={onSelect}
                renderDaysPicker={() =>
                    isDisabled ? null : (
                        <DaysPicker
                            minDate={minDate}
                            onChange={onDateChange}
                            search={search}
                            selectedDate={isSameOrAfter ? search.date_deb : minDate}
                        />
                    )
                }
                renderNoResult={() => (
                    <NoResultFound
                        onDateClick={onDateChange}
                        search={{ ...search, date_min: minDate }}
                    />
                )}
                step={step}
            />
        </div>
    );
}
